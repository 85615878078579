import React, { Component } from 'react';
import _ from 'lodash';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Button, Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { confirmAlert } from 'react-confirm-alert';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { formatNYTimestamp } from '../utils/tzUtils';
import {
  filterConversionsByBmId,
  getAccount,
  getBudgetInfo,
  getCampaignAds,
  getWebsite,
  prepareSiteList,
  getValue
} from './FacebookShared';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  createPreset,
  deletePreset,
  getArticleData,
  getFacebookCampaigns,
  getCreativesByArticle,
  getFbConversionsAndPixels,
  retrievePreset,
  getPresets,
  storeFiles,
  storeVideos,
  updatePreset
} from '../Api';

import AdsetsBuilder from './AdsetsBuilder';

import { FB, GLOBAL, PLATFORMS, TASKS } from '../Constants';
import { toast } from 'react-toastify';
import WidgetSelector from '../WidgetSelector';
import { getInternalConversion, getPurchaseConversion, isPurchaseConversion } from '../FbUtils';
import { parseCampaignName } from '../NameParser';
import Prefix from '../Prefix';
import { normalizeCreative } from '../utils/creativesUtils';
import {
  displayWarnings,
  getFullPath,
  getImageDimensions,
  processFacebookImages,
  processFacebookVideos,
  truncateFileName,
  validateAndPrepareImage
} from '../utils/imageUploadUtils';
import {
  articleCodeLanguageVersion,
  getNewCreatorValue,
  getSelectValue,
  getMultiSelectValue,
  makeSelectOptions,
  getOnlyValue,
  byteSize
} from '../Shared';
import { getNegativeWords } from '../utils/checkForNegativeWords';
import { getPlatformSites } from '../Sites';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CreativeBank from '../CreativeBank';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PresetButtonGroup from './PresetButtonGroup';
import * as Yup from 'yup';
import { styled } from '@mui/system';

const adSchema = Yup.object().shape({
  text: Yup.string()
    .trim()
    .required("Ad's text field must be populated"),
  title: Yup.string().test(
    'maxByteSize',
    `Title is longer than ${FB.MAX_TITLE_LENGTH} bytes`,
    value => byteSize(value) <= FB.MAX_TITLE_LENGTH
  )
});

const adsetSchema = Yup.object().shape({
  geoType: Yup.string().required('Geo type is required'),
  geoName: Yup.string().required('Geo name is required'),
  duplicationType: Yup.string().required('Duplication type is required'),
  site_code: Yup.string().required('Site code is required'),
  conversion: Yup.string().required('Conversion is required'),
  platform: Yup.string().required('Device is required'),
  conversionName: Yup.string().required('Conversion name is required'),
  country: Yup.string().required('Country is required'),
  ads: Yup.array()
    .of(adSchema)
    .min(1, 'Adset should have at least one ad')
    .required('Ads are required')
});

const campaignSchema = Yup.object().shape({
  prefix: Yup.string()
    .required('Prefix is required')
    .matches(/^[a-zA-Z0-9]+$/, 'Invalid prefix format'),
  widgetCodeName: Yup.string().required('Widget code name is required'),
  widgetLanguageCode: Yup.string().required('Widget language code is required'),
  widgetVersion: Yup.string().required('Widget version is required'),
  widget_id: Yup.string().required('Widget ID is required'),
  sourceCampaignName: Yup.string().nullable(),
  presetName: Yup.string().nullable(),
  sourceAccount: Yup.string().nullable(),
  account: Yup.string().required('Account is required'),
  budget: Yup.number().required('Budget is required'),
  objective: Yup.string().required('Objective is required'),
  budgetType: Yup.string().required('Budget type is required'),
  campaign: Yup.object().shape({
    adsets: Yup.array()
      .of(adsetSchema)
      .min(1, 'At least one adset is required')
      .required('Adsets are required')
  })
});

const arraySchema = Yup.array().of(campaignSchema);
const StyledContainer = styled(Grid)(({ theme }) => ({
  padding: '5px',
  borderBottom: '1px solid #a19f9f',
  backgroundColor: '#7fb6f0'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  padding: '10px'
}));

const StyledAdsetToolbar = styled(Grid)(({ theme }) => ({
  padding: '5px',
  borderBottom: '1px solid #a19f9f',
  backgroundColor: '#89c2d8'
}));

/* eslint-disable react/display-name */
const CustomMultiValue = React.forwardRef((props, ref) => (
  <Tooltip title={props.data.label} placement="top">
    <div ref={ref}>
      <components.MultiValue {...props}>{props.children}</components.MultiValue>
    </div>
  </Tooltip>
));

const DEVICE_LIST = ['Mobile', 'Desktop', 'All'];

const DEFAULT_GEO = {
  domain: '',
  site_code: '',
  countries: ['us'],
  duplicationType: 'group',
  geoName: 'us',
  geoType: 'include',
  mobilePlatforms: [],
  campaignNote: [],
  genders: 0,
  age_min: null,
  age_max: null,
  toggleGeo: true,
  toggleAd: true,
  bidStrategyType: 'LOWEST_COST_WITHOUT_CAP',
  bidAmount: 0,
  roasAverageFloor: 0.001,
  device: '',
  platform: 'd',
  adsetTypeToCreate: 'SINGLE',
  adsets: [
    {
      type: 'SINGLE',
      ads: []
    }
  ],
  adNaming: 'Default',
  autoPlacements: false,
  paramSub: false,
  status: 'active',
  videoCreatives: [],
  language: [],
  imagesAmount: '',
  cc: [],
  ageList: {
    minAge: FB.AGE_LIST,
    maxAge: FB.AGE_LIST
  },
  showCreativeBank: false
};

const DEFAULT_CAMPAIGN = {
  destinationAccount: '',
  accountName: '',
  campaignObjective: 'outcome_sales',
  campaignStartTime: moment()
    .add(1, 'day')
    .startOf('day'),
  campaignStopTime: undefined,
  campaignDailyStartHour: undefined,
  campaignDailyStopHour: undefined,
  toggleCampaign: true,
  budgetType: 'daily campaign',
  budget: 15,
  tempBudget: 15,
  bidStrategyType: 'LOWEST_COST_WITHOUT_CAP',
  bidAmount: 0,
  roasAverageFloor: 0.001,
  customConversions: [],
  siteList: [],
  geoList: [DEFAULT_GEO],
  selectArticleCreativeData: {},
  validationErrors: {},
  qs: '',
  multi_advertiser: false,
  text_generation: false
};

class FacebookCreate extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      prefix: '',
      campaignList: [DEFAULT_CAMPAIGN],
      conversion: '',
      conversionList: [],
      widgetCodeName: '',
      widgetLanguageCode: '',
      widgetVersion: '',
      article: '',
      widget_id: '',
      widget_path: '',
      widget_title: '',
      website: '',
      tags: ['Generate_S1'],
      destAccCurrency: '',
      selectArticleCreativeButton: false,
      presetName: '',
      preset: '',
      presets: [],
      campaigns: [],
      sourceCampaignName: '',
      triggerSelectorUpdate: false,
      allSites: [],
      campaignCreator: '',
      sourceAds: [],
      articleData: [],
      imgLink: '',
      languageList: JSON.parse(FB.APP_LANGUAGE_LIST),
      accounts: []
    };
    this.isSwag = this.props.programOptions.isSwag;
    if (this.props.loadedFromSnapshot) {
      this.state = {
        ...this.props.options,
        destinationAccount: this.props.destinationAccount
      };
    } else {
      this.state = JSON.parse(JSON.stringify(this.defaultState));
    }
    // keep cache on state transitions?

    this.countryList = this.props.countryList;
    for (let key in FB.GROUPS_OF_COUNTRYS_LIST) {
      this.countryList.push({
        label: `${key} (${FB.GROUPS_OF_COUNTRYS_LIST[key].join(', ')})`,
        value: FB.GROUPS_OF_COUNTRYS_LIST[key].join(',')
      });
    }
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }

  transformToString(xs) {
    return xs && xs.length ? (Array.isArray(xs) ? xs.join(',') : xs) : '';
  }

  async componentDidMount() {
    if (!this.props.loadedFromSnapshot) {
      const campaignList = this.state.campaignList;
      await this.updateUserOptions();
      this.setPrefixDuplicateCampaign();
      this.setWidgetLanguageCode();
      this.setWidgetVersion();
      await this.readPresets();
      let siteList = this.props.siteList;
      let accounts = this.getDestinationAccountOptions();
      campaignList[0] = { ...this.state.campaignList[0], siteList };
      this.setState({ campaignList, accounts, allSites: prepareSiteList(this.props.notFilteredSiteList) });
    }
  }

  async fetchConversionList(accountId) {
    const conversionsAndPixels = await getFbConversionsAndPixels(accountId);
    return await this.setStateAsync({ conversionList: conversionsAndPixels });
  }

  async updateUserOptions() {
    const userOpts = this.props.programOptions;
    let prefix = userOpts.spm_prefix_for_facebook ? userOpts.spm_prefix_for_facebook : userOpts.spm_prefix_default;
    let creator = getNewCreatorValue(userOpts.email, this.props.creatorsList);
    if (creator) {
      this.updateCreator(creator);
    }
    if (this.isSwag) {
      this.setState({ userMail: userOpts.email });
    }
    this.setState({ prefix });
  }

  setPrefixDuplicateCampaign() {
    if (this.props.task === TASKS.DUPLICATE) {
      this.setState({
        prefix: this.props.prefix
      });
    }
  }

  setWidgetLanguageCode() {
    if (this.props.task === TASKS.CREATE && this.isSwag) {
      this.setState({
        widgetLanguageCode: 'en'
      });
    }
  }

  setWidgetVersion() {
    if (this.props.task === TASKS.CREATE && this.isSwag) {
      this.setState({
        widgetVersion: 'shz'
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.prefix !== prevProps.prefix) {
      this.setPrefixDuplicateCampaign();
    }
    if (this.props.destinationAccount !== prevProps.destinationAccount) {
      const acc = this.props.accounts.find(
        x => x.id === this.props.destinationAccount || x.account_id === this.props.destinationAccount
      );
      this.setState({
        accountName: acc?.prefix || '',
        destAccCurrency: acc?.currency || ''
      });

      if (acc.default_site) {
        await this.updateDomain(acc.default_site);
      }
      await this.fetchConversionList(this.props.destinationAccount);
      this.fetchCampaigns(this.props.destinationAccount);
    }

    if (this.props.task !== prevProps.task) {
      this.setState(JSON.parse(JSON.stringify(this.defaultState)));
      if (!this.props.account) {
        await this.updateUserOptions();
        this.setWidgetLanguageCode();
        this.setWidgetVersion();
      }
    }

    if (this.props.sourceData !== prevProps.sourceData) {
      let parsedData = await this.parseDuplicateData();
      this.setState(parsedData, () => {
        this.setState({ triggerSelectorUpdate: !this.state.triggerSelectorUpdate });
      });
    }
    if (this.props.accounts !== prevProps.accounts) {
      this.setState({ accounts: this.getDestinationAccountOptions() });
    }

    if (this.props.creativeFromFbLibrary) {
      this.props.creativeFbLibraryDone();
      await this.addCreativesFromFbLibrary(this.props.creativeFromFbLibrary, 0, 0);
    }

    if (this.props.creative) {
      this.props.creativeDone();

      let parsedName = {};
      parsedName = parseCampaignName(PLATFORMS[this.props.creative.platform.toUpperCase()], this.props.creative.name);

      setTimeout(() => {
        this.updateCountries([parsedName.country || ''], 0, 0); //From insights, it is always 1 campaign with 1 geoList
        this.updateDomain(parsedName.site || '');
        setTimeout(() => {
          this.setState(
            {
              widgetCodeName: parsedName.widgetCodeName || '',
              widgetLanguageCode: parsedName.widgetLanguageCode,
              widgetVersion: 'shz',
              triggerSelectorUpdate: !this.state.triggerSelectorUpdate
            },
            () => {
              this.updateWebsite(getWebsite(this.state));
            }
          );
        }, 100);
      }, 500);

      const creative = normalizeCreative(this.props.creative);
      await this.addCreatives(
        [
          {
            images: creative.image_url,
            texts: creative.text,
            titles: creative.title
          }
        ],
        0,
        0
      );
    }
    if (prevProps.campaignCreator !== this.props.campaignCreator) {
      this.setState({ campaignCreator: this.props.campaignCreator });
    }
  }
  fetchCampaigns(accountId) {
    getFacebookCampaigns(accountId).then(camps => {
      this.setState({
        campaigns: camps.map(el => {
          return { id: el.id, desc: el.name };
        })
      });
    });
  }

  updateObjective(campaignObjective, campIdx) {
    const campaignList = this.state.campaignList;
    if (campaignObjective === 'outcome_sales') {
      campaignList[campIdx].geoList.forEach(geo => {
        geo.campaignNote = [];
      });
    } else {
      campaignList[campIdx].geoList.forEach(geo => {
        geo.cc = [];
        geo.campaignNote = this.getOneNote(geo.platform, geo.mobilePlatforms);
      });
    }

    campaignList[campIdx] = { ...campaignList[campIdx], campaignObjective };
    this.setState({ campaignList });
  }

  updatePrefix(prefix) {
    if (prefix.target) {
      prefix = prefix.target.value;
    }
    this.setState({ prefix });
  }

  updateCreator(campaignCreator) {
    this.setState({ campaignCreator });
  }

  updateCountries(xs, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    let countries = campaignList[campIdx].geoList[geoIdx].countries;
    let geoType = campaignList[campIdx].geoList[geoIdx].geoType;
    if (geoType === 'exclude' && (xs.indexOf('ww') !== -1 || xs.includes('ww'))) {
      toast.warn('Incorrect country - "worldwide" for excluding. Choose to include country instead');
      return;
    }
    let geoName = '';
    if (countries) {
      if (countries.indexOf('ww') !== -1 && xs !== '' && xs.length) {
        countries = ['ww'];
      } else if (xs.indexOf('ww') !== -1) {
        countries = ['ww'];
      } else {
        countries = xs;
      }
    } else {
      countries = xs;
    }
    if (typeof countries === 'string' && countries !== '') {
      countries = countries.split(',');
    }
    if (
      (Array.isArray(countries) && countries.length === 1 && countries[0].length === 2 && !countries.includes('ww')) ||
      (typeof countries === 'string' && countries.length === 2 && countries !== 'ww')
    ) {
      geoName = xs;
    } else if (!countries || !countries.length) {
      geoName = '';
    } else {
      geoName = 'ww';
    }

    if (geoType === 'exclude') {
      geoName = 'ww';
    }

    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], countries, geoName };
    this.setState({ campaignList });
  }

  updateGeo(xs, geoType) {
    if (geoType === 'exclude' && (xs.indexOf('ww') !== -1 || xs.includes('ww'))) {
      toast.warn('Incorrect country - "worldwide" for excluding. Choose to include country instead');
      return;
    }
    let geoName = '';
    let countries = xs;
    if (countries) {
      if (countries.indexOf('ww') !== -1 && xs !== '' && xs.length) {
        countries = ['ww'];
      } else if (xs.indexOf('ww') !== -1) {
        countries = ['ww'];
      } else {
        countries = xs;
      }
    } else {
      countries = xs;
    }
    if (typeof countries === 'string' && countries !== '') {
      countries = countries.split(',');
    }
    if (
      (Array.isArray(countries) && countries.length === 1 && countries[0].length === 2 && !countries.includes('ww')) ||
      (typeof countries === 'string' && countries.length === 2 && countries !== 'ww')
    ) {
      geoName = xs;
    } else if (!countries || !countries.length) {
      geoName = '';
    } else {
      geoName = 'ww';
    }
    if (geoType === 'exclude') {
      geoName = 'ww';
    }

    return geoName;
  }

  updateCountriesGroup(geoType, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], geoType };
    this.setState({ campaignList }, () => {
      this.updateCountries(campaignList[campIdx].geoList[geoIdx].countries, campIdx, geoIdx);
    });
  }

  updateDuplicationType(duplicationType, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], duplicationType };
    this.setState({ campaignList });
  }

  fetchOneConversionList(accountId) {
    return getFbConversionsAndPixels(accountId);
  }

  fetchOneConversionListCached(accountId) {
    return getFbConversionsAndPixels(accountId, true);
  }

  getDefaultSite(acc, siteList) {
    if (acc && acc.default_site) {
      const site = siteList.find(el => acc.default_site.includes(el.value));
      return site ? site.value : '';
    }
  }

  getUpdatedSiteList(destinationAccount, acc) {
    let siteList;
    if (FB.ALL_SITES_ACCOUNT_LIST.includes(destinationAccount)) {
      siteList = this.state.allSites.filter(el => !FB.VIP_SITES_LIST.includes(el.value));
    } else {
      siteList = this.props.siteList;
    }
    if (acc && acc.desc) {
      siteList = siteList.filter(el =>
        acc.default_site && acc.default_site !== 'ALL' ? acc.default_site.includes(el.value) : true
      );
    }
    return siteList;
  }

  async updateDestinationAccount(destinationAccount, campIdx) {
    this.props.startLoading();

    if (!destinationAccount) {
      this.props.endLoading();
      return;
    }
    const acc = getAccount(destinationAccount, this.props.accounts);

    if (!acc || !acc.business_mgr_id) {
      return;
    }

    let bmId = null;

    if (this.state.blacklistedAds && this.state.blacklistedAds.length) {
      let blacklisted = this.state.blacklistedAds;
      for (let bm of blacklisted) {
        if (bm.blacklisted_bm && bm.blacklisted_bm[acc.business_mgr_id]) {
          toast.error(
            `Ads from the "${bm.campaign_name}" cannot be used with the selected business ID: ${acc.business_mgr_id}. Ads are blacklisted for this account`
          );
          this.props.endLoading();
          return;
        }
      }
    }
    let { widget_id } = this.state;
    let optionalFilter = { filterConverions: false, cc: FB.QUIZ_CONVERSIONS };

    if (widget_id && widget_id.includes(FB.QUIZ)) {
      optionalFilter.filterConverions = true;
    }

    let customConversions;
    const siteList = this.getUpdatedSiteList(destinationAccount, acc);
    const domain = this.getDefaultSite(acc, siteList);

    customConversions = await this.fetchOneConversionList(destinationAccount);
    if (customConversions) {
      customConversions = await filterConversionsByBmId(
        customConversions,
        acc.business_mgr_id,
        this.props.userMail,
        optionalFilter,
        FB.DYNAMIC_CC_USERS,
        this.isSwag
      );
    }
    bmId = acc.business_mgr_id;
    const campaignList = this.state.campaignList;
    let geoList = campaignList[campIdx].geoList;

    geoList.forEach(geo => {
      geo.cc = [];
      geo.campaignNote = [];
    });

    campaignList[campIdx].geoList = geoList;
    campaignList[campIdx] = {
      ...campaignList[campIdx],
      destinationAccount,
      customConversions,
      accountName: acc.prefix,
      accountCurrency: acc?.currency,
      currencyMul: acc?.currency_mul,
      bmId,
      siteList
    };
    this.setState({ campaignList }, () => {
      this.updateOneDomain(domain, campIdx, 0);
    });
    this.props.endLoading();
  }

  updateOneAccountName(accountName, idx) {
    const campaignList = this.state.campaignList;
    campaignList[idx] = { ...campaignList[idx], accountName };
    this.setState({ campaignList });
  }

  updateMobilePlatforms(campIdx, geoIdx, platform) {
    const { campaignList } = this.state;
    const { mobilePlatforms } = campaignList[campIdx].geoList[geoIdx];

    const index = mobilePlatforms.indexOf(platform);

    if (index === -1) {
      mobilePlatforms.push(platform);
    } else {
      mobilePlatforms.splice(index, 1);
    }

    let cc = campaignList[campIdx].geoList[geoIdx].cc ? campaignList[campIdx].geoList[geoIdx].cc : [];
    campaignList[campIdx].geoList[geoIdx].campaignNote = cc.map(customConversion =>
      this.getInternalNote(
        customConversion.value,
        campaignList[campIdx].geoList[geoIdx].platform,
        mobilePlatforms,
        campaignList[campIdx].customConversions
      )
    );
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], mobilePlatforms };
    this.setState({ campaignList });
  }
  getDeviceList() {
    return DEVICE_LIST.map(x => ({ label: x, value: x }));
  }
  updateOneDomain(site_code, campIdx, geoIdx) {
    const { campaignList } = this.state;
    const { programOptions } = this.props;
    if (site_code) {
      let platforms = ['d', 'm'];
      let website = getWebsite(this.state, site_code);
      if (!this.isSwag || programOptions.email === FB.LEON_USER) {
        platforms.push('a');
      }
      campaignList[campIdx].geoList[geoIdx] = {
        ...campaignList[campIdx].geoList[geoIdx],
        site_code,
        platforms,
        website
      };
      this.updateWebsite(getWebsite(this.state));
      this.setState({ campaignList });
    }
  }

  updateOneCampaignNote(campaignNote, campIdx, geoIdx, noteIdx) {
    const campaignList = this.state.campaignList;
    if (noteIdx === undefined) {
      campaignList[campIdx].geoList[geoIdx].campaignNote = campaignNote;
    } else {
      campaignList[campIdx].geoList[geoIdx].campaignNote[noteIdx] = campaignNote;
    }
    this.setState({ campaignList });
  }

  updateGeoName(geoName, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], geoName: geoName.trim() };
    this.setState({ campaignList });
  }

  updateOneGender(genders, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], genders };
    this.setState({ campaignList });
  }

  updateOneMinAge(age_min, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    let ageList = campaignList[campIdx].geoList[geoIdx].ageList;
    ageList.maxAge = age_min ? ageList.maxAge.filter(x => x.value >= age_min) : FB.AGE_LIST;
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], age_min, ageList };
    this.setState({ campaignList });
  }

  updateOneMaxAge(age_max, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    let ageList = campaignList[campIdx].geoList[geoIdx].ageList;
    ageList.minAge = age_max ? ageList.minAge.filter(x => x.value <= age_max) : FB.AGE_LIST;
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], age_max, ageList };
    this.setState({ campaignList });
  }

  updateLanguages(selectedOptions, campIdx, geoIdx) {
    const campaignList = [...this.state.campaignList];
    campaignList[campIdx].geoList[geoIdx] = {
      ...campaignList[campIdx].geoList[geoIdx],
      language: selectedOptions
    };
    this.setState({ campaignList });
  }

  checkBudgetType(type) {
    return !!(type === 'daily campaign' || type === 'lifetime campaign');
  }
  updateBudgetType(budgetType, idx) {
    const campaignList = this.state.campaignList;
    campaignList[idx] = { ...campaignList[idx], budgetType };
    this.setState({ campaignList });
  }

  updateBudget(budget, idx) {
    const campaignList = this.state.campaignList;
    if (budget === '' || parseInt(budget, 10) >= FB.MIN_BUDGET) {
      campaignList[idx] = { ...campaignList[idx], budget, tempBudget: budget };
      this.setState({ campaignList });
    }
  }

  handleBudgetChange(e, idx) {
    const value = e.target.value;
    const campaignList = this.state.campaignList;
    campaignList[idx] = { ...campaignList[idx], tempBudget: value };
    this.setState({ campaignList });
  }

  handleBudgetBlur(idx) {
    const campaignList = this.state.campaignList;
    let tempBudget = campaignList[idx].tempBudget;
    if (tempBudget === '' || parseInt(tempBudget, 10) >= FB.MIN_BUDGET) {
      this.updateBudget(tempBudget, idx);
    } else {
      toast.error('Budget must be at least 15');
      campaignList[idx] = { ...campaignList[idx], tempBudget: FB.MIN_BUDGET };
      this.setState({ campaignList });
      this.updateBudget(FB.MIN_BUDGET, idx);
    }
  }

  updateBudgetEndTime(budgetEndTime, idx) {
    const campaignList = this.state.campaignList;
    campaignList[idx] = { ...campaignList[idx], budgetEndTime };
    this.setState({ campaignList });
  }

  updateBidStrategyType(bidStrategyType, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    if (geoIdx === undefined) {
      campaignList[campIdx] = { ...campaignList[campIdx], bidStrategyType };
    } else {
      campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], bidStrategyType };
    }
    if (bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS') {
      campaignList[campIdx].geoList.forEach(e => {
        e.cc = [];
        e.campaignNote = [];
      });
    }
    this.setState({ campaignList });
  }
  updateBidAmount(bidAmount, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    if (geoIdx === undefined) {
      campaignList[campIdx] = { ...campaignList[campIdx], bidAmount };
      this.setState({ campaignList });
    } else {
      campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], bidAmount };
      this.setState({ campaignList });
    }
  }

  updateROASAverageFloor(roasAverageFloor, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    if (geoIdx === undefined) {
      campaignList[campIdx] = { ...campaignList[campIdx], roasAverageFloor };
      this.setState({ campaignList });
    } else {
      campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], roasAverageFloor };
      this.setState({ campaignList });
    }
  }

  updateCampaignStartTime(campaignStartTime, idx) {
    const campaignList = this.state.campaignList;
    campaignList[idx] = { ...campaignList[idx], campaignStartTime };
    this.setState({ campaignList });
  }

  updateCampaignStopTime(campaignStopTime, idx) {
    const campaignList = this.state.campaignList;
    campaignList[idx] = { ...campaignList[idx], campaignStopTime };
    this.setState({ campaignList });
  }

  updateCampaignDailyStartHour(campaignDailyStartHour, idx) {
    const campaignList = this.state.campaignList;
    campaignList[idx] = { ...campaignList[idx], campaignDailyStartHour };
    this.setState({ campaignList });
  }

  updateCampaignDailyStopHour(campaignDailyStopHour, idx) {
    const campaignList = this.state.campaignList;
    campaignList[idx] = { ...campaignList[idx], campaignDailyStopHour };
    this.setState({ campaignList });
  }

  toggleMultiAdvertiser(campIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].multi_advertiser = !campaignList[campIdx].multi_advertiser;
    this.setState({ campaignList });
  }

  toggleTextGeneration(campIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].text_generation = !campaignList[campIdx].text_generation;
    this.setState({ campaignList });
  }

  handleCampDropdown(idx) {
    const { campaignList } = this.state;
    campaignList[idx].toggleCampaign = !campaignList[idx].toggleCampaign;
    campaignList[idx].geoList.forEach(geo => {
      geo.toggleGeo = campaignList[idx].toggleCampaign;
      geo.toggleAd = campaignList[idx].toggleCampaign;
    });
    this.setState({ campaignList });
  }
  handleGeoDropdown(campIdx, idx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[idx].toggleGeo = !campaignList[campIdx].geoList[idx].toggleGeo;
    this.setState({ campaignList });
  }
  handleAdsDropdown(campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].toggleAd = !campaignList[campIdx].geoList[geoIdx].toggleAd;
    this.setState({ campaignList });
  }

  getHours() {
    return Array.from({ length: 25 }, (v, i) => {
      return { label: `${i}:00`, value: i };
    });
  }

  updateStatus(status, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], status };
    this.setState({ campaignList });
  }

  async updateDomain(site_code) {
    await this.setStateAsync({ site_code });
    await this.modifyDomainRows();
  }

  updateWebsite(website) {
    this.setState({ website });
  }

  updateTags(tags) {
    this.setState({ tags });
  }

  toggleAutoPlacements(campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].autoPlacements = !campaignList[campIdx].geoList[geoIdx].autoPlacements;
    this.setState({ campaignList });
  }

  addParamSub(campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].paramSub = !campaignList[campIdx].geoList[geoIdx].paramSub;
    this.setState({ campaignList });
  }

  updateWidgetFields(key, value) {
    const { widgetCodeName, widgetLanguageCode, widgetVersion } = this.state;
    const { sourceData } = this.props;
    value = key === 'article' ? value.target.value : value;
    key = key === 'widgetid' ? 'widget_id' : key;
    if (key === 'article' && this.props.spmCreativeBankAccess) {
      this.selectArticleCreative(value);
    }
    this.setState({ [key]: value }, () => {
      if (sourceData && widgetCodeName && widgetLanguageCode && widgetVersion) {
        this.updateWebsite(getWebsite(this.state, sourceData.site_code));
      } else {
        this.updateWebsite(getWebsite(this.state));
      }
    });
  }

  updateAdNaming(adNaming, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], adNaming };
    this.setState({ campaignList });
  }

  getOptionsNote(cc, geoList, camp) {
    let parts = [];
    if (geoList.platform === 'm') {
      parts.push(geoList.mobilePlatforms === 'Android' ? 'and' : geoList.mobilePlatforms === 'iOS' ? 'ios' : 'all');
    } else {
      if (isPurchaseConversion(cc)) {
        parts.push(`purchase`);
      } else {
        parts.push('desc');
      }
    }
    parts.push(`CC_${cc.slice(cc.indexOf(' '))}`);
    let bidStrategyType;
    if (this.checkBudgetType(camp.budgetType)) {
      bidStrategyType =
        camp.bidStrategyType === 'LOWEST_COST_WITHOUT_CAP'
          ? 'LC'
          : camp.bidStrategyType === 'LOWEST_COST_WITH_BID_CAP'
          ? 'BC'
          : 'CC';
      if (bidStrategyType === 'BC' || bidStrategyType === 'BC') {
        bidStrategyType += `(${camp.bidAmount})`;
      }
    } else {
      bidStrategyType =
        geoList.bidStrategyType === 'LOWEST_COST_WITHOUT_CAP'
          ? 'LC'
          : geoList.bidStrategyType === 'LOWEST_COST_WITH_BID_CAP'
          ? 'BC'
          : 'CC';
      if (bidStrategyType === 'BC' || bidStrategyType === 'BC') {
        bidStrategyType += `(${geoList.bidAmount})`;
      }
    }
    parts.push(bidStrategyType);
    if (geoList.genders) {
      parts.push(geoList.genders === 1 ? 'Men' : 'Women');
    }
    if (geoList.age_min) {
      parts.push(`min_${geoList.age_min})`);
    }
    if (geoList.age_max) {
      parts.push(`max_${geoList.age_max})`);
    }
    return parts.join('_');
  }
  getOneNote(platform, mobPlatform) {
    let parts = [];
    parts.push(this.props.userMail.slice(0, this.props.userMail.indexOf('@')));

    if (platform === 'a') {
      parts.push('All');
    } else if (platform === 'm') {
      parts.push('Mobile');
    } else if (platform === 'd') {
      parts.push('Desktop');
    }
    if (platform === 'm') {
      parts.push(
        mobPlatform.includes('Android') && !mobPlatform.includes('iOS')
          ? 'and'
          : !mobPlatform.includes('Android') && mobPlatform.includes('iOS')
          ? 'ios'
          : 'all'
      );
    }
    return parts.join(' / ');
  }

  getInternalNote(cc, platform, mobPlatform, conversions) {
    let parts = [];
    parts.push(this.props.userMail.slice(0, this.props.userMail.indexOf('@')));
    if (isPurchaseConversion(cc)) {
      if (cc.includes('capi')) {
        parts.push(`purchase-s2s`);
      } else {
        parts.push(`purchase`);
      }
    } else if (cc.includes('depc')) {
      parts.push(cc.slice(cc.indexOf(' ')));
    } else {
      const conversion = conversions.find(el => el.id === cc);
      const start = conversion.name.indexOf('0.');
      parts.push(conversion.name.slice(start, start + 4));
    }

    const conversion = conversions.find(el => el.id === cc);
    const allSites = conversion && conversion.name.includes('All');
    if (platform === 'a' || (allSites && platform !== 'm')) {
      parts.push('All');
    } else if (platform === 'm') {
      parts.push('Mobile');
    } else if (platform === 'd') {
      parts.push('Desktop');
    }
    if (platform === 'm') {
      parts.push(
        mobPlatform.includes('Android') && !mobPlatform.includes('iOS')
          ? 'and'
          : !mobPlatform.includes('Android') && mobPlatform.includes('iOS')
          ? 'ios'
          : 'all'
      );
    }
    return parts.join(' / ');
  }

  handleOneCcSelect(ccId, campIdx, geoIdx) {
    let campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].cc = ccId;
    if (typeof ccId === 'string') {
      ccId = ccId.split(',');
    }

    campaignList[campIdx].geoList[geoIdx].campaignNote = ccId.map(el => {
      return FB.FB_OPTIONS_NOTE_USERS.includes(this.props.userMail)
        ? this.getOptionsNote(el.value, campaignList[campIdx].geoList[geoIdx], campaignList[campIdx])
        : this.getInternalNote(
            el.value,
            campaignList[campIdx].geoList[geoIdx].platform,
            campaignList[campIdx].geoList[geoIdx].mobilePlatforms,
            campaignList[campIdx].customConversions
          );
    });
    this.setState({ campaignList });
  }

  async modifyDomainRows() {
    let campaignList = this.state.campaignList;
    if (!this.state.site_code) {
      return true;
    }

    let domains = this.state.site_code;
    if (typeof domains === 'string') {
      domains = domains.split(',');
    }

    let platforms = ['d', 'm'];
    domains.forEach(dom => {
      this.updateWebsite(getWebsite(this.state, dom));
    });
    campaignList[0].geoList[0].platforms = platforms;
    this.setState({ campaignList });
  }
  // maybe move creation logic to the builder?
  addAdset(adset, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    if (adset === undefined) {
      switch (campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate) {
        case 'SINGLE':
          campaignList[campIdx].geoList[geoIdx].adsets.push({
            type: campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate,
            ads: []
          });
          break;
        case 'SINGLE_VIDEO':
          campaignList[campIdx].geoList[geoIdx].adsets.push({
            type: campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate,
            ads: []
          });
          break;
        case 'DYNAMIC': // in dynamic adset image/imageHashes should have the same length
          campaignList[campIdx].geoList[geoIdx].adsets.push({
            type: campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate,
            ads: [
              {
                image: [],
                text: [''],
                title: ['']
              }
            ]
          });
          break;
        default:
          throw new Error(`Unknown adset type: ${campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate}`);
      }
    } else {
      campaignList[campIdx].geoList[geoIdx].adsets.push(adset);
    }
    this.setState({ campaignList });
  }

  deleteAdset(adIdx, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].adsets.splice(adIdx, 1);
    this.setState({ campaignList });
  }
  modifyAdset(adIdx, adset, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].adsets[adIdx] = adset;
    this.setState({ campaignList });
  }

  modifyAdsets(ad, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].adsets.push({
      type: campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate,
      ads: [ad]
    });
    this.setState({ campaignList });
  }

  populateAllText(adset, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].adsets.forEach(a =>
      a.ads.forEach(ad => {
        ad.title = adset.title;
        ad.text = adset.text;
      })
    );
    this.setState({ campaignList });
  }

  getSubmitButtonText() {
    return 'Create';
  }

  getAllowedWidgetVersions() {
    // If some selected domain allows only a subset of widget versions, return it instead of all allowed versions
    //TODO prepare new logic
    /*let sites = this.state.site_code;
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    for(const s of sites) {
      if (FB.SITE_ALLOWED_VERSIONS[s]) {
        return FB.SITE_ALLOWED_VERSIONS[s];
      }
    }*/
    return this.isSwag ? FB.ALLOWED_VERSIONS : FB.B2B_ALLOWED_VERSIONS;
  }

  updateImagesAmount(imagesAmount, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], imagesAmount };
    this.setState({ campaignList });
  }

  filteredCreatives(data) {
    if (!Array.isArray(data) || data.length === 0) {
      return;
    }
    return data.filter(async e => {
      const negativeTextsWords = await getNegativeWords(PLATFORMS.FACEBOOK, e.text);
      const negativeTitlesWords = await getNegativeWords(PLATFORMS.FACEBOOK, e.title);
      return !negativeTextsWords.length && !negativeTitlesWords.length;
    });
  }
  validateArticles() {
    const clv = articleCodeLanguageVersion(
      this.props.articleList,
      this.state.widget_id,
      this.state.article,
      '',
      this.isSwag ? FB.ARTICLE_BLOCK_SWAG : FB.ARTICLE_BLOCK_B2B
    );
    if (!clv) return false;
    return clv.every(value => typeof value === 'string' && value !== '');
  }

  validate(condition, errorMessage) {
    return () => {
      if (!condition) {
        console.warn(errorMessage);
        toast.error(errorMessage);
        return false;
      }
      return true;
    };
  }

  isValidWidgetId() {
    return this.validate(this.state.widget_id, FB.ERRORS.MISSED_WIDGET_ID);
  }

  isValidArticle() {
    return this.validate(this.validateArticles(), FB.ERRORS.BLOCKED_ARTICLE);
  }

  isValidDestinationAccount() {
    return this.validate(
      this.state.campaignList.every(camp => camp.destinationAccount),
      FB.ERRORS.MISSED_DESTINATION_ACCOUT
    );
  }
  isValidBmId() {
    let condition = this.state.campaignList.every(
      camp => !this.state.blacklistedAds.every(bm => bm.blacklisted_bm && bm.blacklisted_bm[camp.bmId])
    );

    return this.validate(condition, FB.ERRORS.BLOCKED_CREATIVE_FOR_BM_ID);
  }

  isValidAdsetObjectiveWithStrategy() {
    let condition = this.state.campaignList.every(camp =>
      camp.geoList.every(
        geo => !(camp.campaignObjective === 'outcome_traffic' && geo.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS')
      )
    );

    return this.validate(condition, FB.ERRORS.INVALID_OBJECTIVE);
  }

  isValidCampObjectiveWithStrategy() {
    let condition = this.state.campaignList.every(
      camp => !(camp.campaignObjective === 'outcome_traffic' && camp.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS')
    );
    return this.validate(condition, FB.ERRORS.INVALID_OBJECTIVE);
  }

  isValidGeoWithType() {
    let condition = this.state.campaignList.every(camp =>
      camp.geoList.every(geo => !(geo.countries.includes('ww') && geo.geoType === 'exclude'))
    );

    return this.validate(condition, FB.ERRORS.INVALID_GEO_WITH_TYPE);
  }

  isValidGeo() {
    let condition = this.state.campaignList.every(camp => camp.geoList.every(geo => !geo.countries.includes('uk')));
    return this.validate(condition, FB.ERRORS.INVALID_GEO_GB);
  }

  isValidDomain = geo => {
    return this.validate(geo.site_code.length, FB.ERRORS.EMPTY_DOMAIN);
  };

  isValidDevice = geo => {
    return this.validate(geo.device.length, FB.ERRORS.EMPTY_DEVICE);
  };
  isValidBidAmount = geo => {
    return this.validate(geo.bidAmount > 0, FB.ERRORS.LOW_ADSET_BID_AMOUNT);
  };

  isValidCampaignNote = geo => {
    return this.validate(geo.campaignNote.length, FB.ERRORS.EMPTY_CAMPAIGN_NOTE);
  };

  isValidBudgetEndTime(camp) {
    return this.validate(camp.budgetEndTime, FB.ERRORS.BUDGET_END_TIME);
  }

  isValidBidCampAmount(camp) {
    return this.validate(camp.bidAmount > 0, FB.ERRORS.LOW_CAMPAIGN_BID_AMOUNT);
  }

  isValidCc = geo => {
    return this.validate(geo.cc.length > 0, FB.ERRORS.EMPTY_CC);
  };

  isValidPlatform() {
    return this.validate(this.state.platform, FB.ERRORS.INVALID_PLATFORM);
  }

  isLowBudget(camp, index) {
    return this.validate(
      camp.budget >= camp.geoList.length * 2,
      `Campaign - ${index + 1}, budget - ${camp.budget} is too low for ${
        camp.geoList.length
      } adsets.  Your campaign budget must be at least ${camp.geoList.length * 2}`
    );
  }

  isValidAge() {
    let condition = this.state.campaignList.every(camp =>
      camp.geoList.every(geo => !(geo.countries.includes('ww') && geo.age_min !== null && geo.age_min < 18))
    );
    return this.validate(condition, FB.ERRORS.INVALID_AGE);
  }

  validateGeoList = (camp, validationFunction) => {
    // eslint-disable-next-line array-callback-return
    return camp.geoList
      .flatMap(geo => {
        return validationFunction(geo);
      })
      .filter(Boolean);
  };

  addCampaignValidations(validations, campaign, index) {
    validations.push(this.isLowBudget(campaign, index));

    if (this.state.blacklistedAds && this.state.blacklistedAds.length > 0) {
      validations.push(this.isValidBmId());
    }

    if (campaign.budgetType.includes('lifetime')) {
      validations.push(this.isValidBudgetEndTime());
    }
    if (campaign.campaignObjective !== 'outcome_traffic') {
      validations.push(...this.validateGeoList(campaign, this.isValidCc));
    }

    if (this.checkBudgetType(campaign.budgetType)) {
      if (campaign.bidStrategyType === 'LOWEST_COST_WITH_BID_CAP' || campaign.bidStrategyType === 'COST_CAP') {
        validations.push(this.isValidBidCampAmount(campaign));
      }
    } else {
      validations.push(...this.validateGeoList(campaign, this.isValidBidAmount));
      validations.push(...this.validateGeoList(campaign, this.isValidCampaignNote));
    }
  }
  //TODO move to the separate component
  validateData = async data => {
    try {
      await arraySchema.validate(data, { abortEarly: false });
      this.setState({ validationErrors: {} });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
        Object.keys(newErrors).forEach(key => {
          toast.error(newErrors[key]);
        });
        this.setState({ validationErrors: newErrors });
      }
    }
  };

  validateSetup() {
    let validations = [];
    if (this.props.platform !== PLATFORMS.FACEBOOK) {
      validations.push(this.isValidPlatform());
    }

    validations.push(
      this.isValidWidgetId(),
      this.isValidArticle(),
      this.isValidDestinationAccount(),
      this.isValidAdsetObjectiveWithStrategy(),
      this.isValidCampObjectiveWithStrategy(),
      this.isValidGeo(),
      this.isValidGeoWithType(),
      this.isValidAge()
    );

    this.state.campaignList.forEach((campaign, index) => {
      validations.push(...this.validateGeoList(campaign, this.isValidDomain));
      validations.push(...this.validateGeoList(campaign, this.isValidDevice));
      this.addCampaignValidations(validations, campaign, index);
    });

    return validations.every(validation => validation());
  }

  async validateAdsets() {
    const xs = this.state.campaignList.flatMap((camp, campIdx) =>
      camp.geoList.flatMap((geo, geoIdx) =>
        geo.adsets.flatMap(async (adset, adsetIdx) => {
          switch (adset.type) {
            case 'SINGLE':
              // eslint-disable-next-line no-case-declarations
              let errorsSingle = [
                this.validate(adset.ads.length > 0, 'SINGLE adset should have at least one ad'),
                this.validate(
                  adset.ads.every(ad => ad.image),
                  "SINGLE adset's ad should have all images added"
                ),
                this.validate(
                  adset.ads.every(ad => ad.text.trim() !== ''),
                  "SINGLE adset's ad should have all text fields populated"
                ),
                this.validate(
                  adset.ads.every(ad => byteSize(ad.title) <= FB.MAX_TITLE_LENGTH),
                  `Campaign ${campIdx + 1}, Adset ${geoIdx + 1}, title - is longer than 255 bytes`
                )
              ];
              if (this.props.spmCreativeBankAccess || this.props.programOptions.spm_check_negative_words) {
                await Promise.all(
                  adset.ads.map(async (ad, idx) => {
                    const negativeTextsWords = await getNegativeWords(PLATFORMS.FACEBOOK, ad.text);
                    const negativeTitlesWords = await getNegativeWords(PLATFORMS.FACEBOOK, ad.title);
                    if (negativeTextsWords.length) {
                      errorsSingle.push(
                        this.validate(
                          false,
                          `You're trying to use a sensational words.\n
                Adset ${adsetIdx + 1}, ad ${idx + 1} text:\n
                ${negativeTextsWords.join('\n')}`
                        )
                      );
                    }
                    if (negativeTitlesWords.length) {
                      errorsSingle.push(
                        this.validate(
                          false,
                          `You're trying to use a sensational words.\n
                Adset ${adsetIdx + 1}, ad ${idx + 1} title:\n
                ${negativeTitlesWords.join('\n')}`
                        )
                      );
                    }
                  })
                );
              }
              return errorsSingle;
            case 'SINGLE_VIDEO':
              return [
                this.validate(adset.ads.length > 0, 'SINGLE adset should have at least one ad'),
                this.validate(
                  adset.ads.every(ad => byteSize(ad.title) <= FB.MAX_TITLE_LENGTH),
                  `Campaign ${campIdx + 1}, Adset ${geoIdx + 1}, title - is longer than 255 bytes`
                )
              ];
            case 'DYNAMIC':
              // eslint-disable-next-line no-case-declarations
              let errorsDynamic = [
                this.validate(adset.ads[0].image.length > 0, 'Dynamic adset should have at least one image'),
                this.validate(adset.ads[0].image[0], 'Dynamic adset should have at least one image'),
                this.validate(
                  adset.ads[0].text.filter(x => x.trim() !== '').length > 0,
                  'Dynamic adset should have at least one text'
                ),
                this.validate(
                  adset.ads.every(ad => byteSize(ad.title) <= FB.MAX_TITLE_LENGTH),
                  `Campaign ${campIdx + 1}, Adset ${geoIdx + 1}, title - is longer than 255 bytes`
                )
              ];
              if (this.props.spmCreativeBankAccess || this.props.programOptions.spm_check_negative_words) {
                await Promise.all(
                  adset.ads[0].text.map(async (text, idx) => {
                    const negativeTextsWords = await getNegativeWords(PLATFORMS.FACEBOOK, text);
                    if (negativeTextsWords.length) {
                      errorsDynamic.push(
                        this.validate(
                          false,
                          `You're trying to use a sensational words.\n
                Adset ${adsetIdx + 1}, ad ${idx + 1} text:\n
                ${negativeTextsWords.join('\n')}`
                        )
                      );
                    }
                  })
                );
                await Promise.all(
                  adset.ads[0].title.map(async (title, idx) => {
                    const negativeTitlesWords = await getNegativeWords(PLATFORMS.FACEBOOK, title);
                    if (negativeTitlesWords.length) {
                      errorsDynamic.push(
                        this.validate(
                          false,
                          `You're trying to use a sensational words.\n
                Adset ${adsetIdx + 1}, ad ${idx + 1} text:\n
                ${negativeTitlesWords.join('\n')}`
                        )
                      );
                    }
                  })
                );
              }
              return errorsDynamic;

            default:
              throw new Error(`Unknown adset type: ${adset.type}`);
          }
        })
      )
    );
    return Promise.all(xs).then(errors => errors.flat().every(x => x()));
  }

  transformCampaigns() {
    const {
      campaignList,
      prefix,
      widgetCodeName,
      widgetLanguageCode,
      widgetVersion,
      article,
      widget_id,
      widget_path,
      widget_title,
      campaignCreator,
      sourceAds,
      sourceCampaignName
    } = this.state;
    let { tags } = this.state;
    const pacing_type = ['standard'];
    const billing_event = 'IMPRESSIONS';
    const result = [];

    campaignList.forEach(campaign => {
      const transformedCampaign = {
        prefix,
        pacing_type,
        billing_event,
        widgetCodeName,
        widgetLanguageCode,
        widgetVersion,
        article,
        widget_id,
        widget_path,
        widget_title,
        sourceAds,
        sourceCampaignName,
        isSwag: this.isSwag,
        fromPlatform: PLATFORMS.FACEBOOK,
        presetName: this.state.preset,
        sourceAccount: this.props.account,
        account: campaign.destinationAccount,
        accountCurrency: campaign?.accountCurrency,
        currencyMul: campaign?.currencyMul,
        budget: campaign.budget,
        objective: campaign.campaignObjective.toUpperCase(),
        budgetType: campaign.budgetType,
        start_time: campaign.campaignStartTime ? formatNYTimestamp(moment(campaign.campaignStartTime)) : undefined,
        stop_time: campaign.campaignStopTime ? formatNYTimestamp(campaign.campaignStopTime) : undefined,
        daily_start_hour:
          campaign.campaignDailyStartHour || campaign.campaignDailyStartHour === 0
            ? campaign.campaignDailyStartHour
            : undefined,
        daily_stop_hour: campaign.campaignDailyStopHour ? campaign.campaignDailyStopHour : undefined,
        campaignCreator: this.state.campaignCreator ? getNewCreatorValue(campaignCreator, this.props.creatorsList) : '',
        end_time: campaign.budgetEndTime ? formatNYTimestamp(campaign.budgetEndTime) : undefined,
        campaign: { adsets: [] },
        multi_advertiser: campaign.multi_advertiser,
        text_generation: campaign.text_generation
      };

      if (this.isSwag) {
        tags = Array.isArray(tags) ? tags.join(',') : tags;
        if (this.props.task === TASKS.DUPLICATE) {
          tags = tags ? (tags.includes(GLOBAL.TAG) ? tags : `${tags},${GLOBAL.TAG}`) : GLOBAL.TAG;
        }
        transformedCampaign.tags = tags;
        transformedCampaign.qs = campaign.qs;
      }

      if (this.checkBudgetType(campaign.budgetType)) {
        transformedCampaign.bid_strategy = campaign.bidStrategyType;
        transformedCampaign.bid_amount = campaign.bidAmount;
        transformedCampaign.bid_constraints =
          campaign.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS'
            ? { roas_average_floor: campaign.roasAverageFloor * 10000 }
            : undefined;
      }
      campaign.geoList.forEach(geo => {
        if (campaign.campaignObjective !== 'outcome_traffic') {
          geo.cc.forEach((ccItem, index) => {
            const adset = {
              geoType: geo.geoType,
              geoName: Array.isArray(geo.geoName) ? geo.geoName.join('') : geo.geoName,
              duplicationType: geo.duplicationType,
              site_code: geo.site_code,
              conversion: ccItem.value,
              conversionName: ccItem.label,
              country: geo.countries.join(','),
              campaignNote: geo.campaignNote[index],
              ads: geo.adsets.flatMap(e => {
                e.ads.forEach(ad => {
                  ad.type = geo.adsetTypeToCreate;
                });
                return e.ads;
              }),
              mobilePlatforms: this.transformToString(geo.mobilePlatforms),
              genders: geo.genders,
              age_min: geo.age_min,
              age_max: geo.age_max,
              status: prefix === 'xx' ? 'PAUSED' : geo.status.toUpperCase(),
              platform: geo.platform,
              language: this.transformToString(geo.language),
              adNaming: geo.adNaming,
              autoPlacements: geo.autoPlacements,
              type: geo.adsetTypeToCreate
            };
            if (this.isSwag && geo.paramSub) {
              adset.subid4 = true;
            }
            if (campaign.budgetType === 'daily adset' || campaign.budgetType === 'lifetime adset') {
              adset.bid_strategy = geo.bidStrategyType;
              adset.bid_amount = geo.bidAmount;
              adset.bid_constraints =
                geo.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS'
                  ? { roas_average_floor: geo.roasAverageFloor * 10000 }
                  : undefined;
            }
            transformedCampaign.campaign.adsets.push(adset);
            if (prefix === 'xx') {
              transformedCampaign.campaign.status = 'PAUSED';
            }
          });
        } else {
          const adset = {
            geoType: geo.geoType,
            geoName: geo.geoName,
            duplicationType: geo.duplicationType,
            site_code: geo.site_code,
            conversion: '',
            country: geo.countries.join(','),
            campaignNote: geo.campaignNote,
            ads: geo.adsets.flatMap(e => {
              e.ads.forEach(ad => {
                ad.type = geo.adsetTypeToCreate;
              });
              return e.ads;
            }),
            mobilePlatforms: geo.mobilePlatforms && geo.mobilePlatforms.length ? geo.mobilePlatforms.join(',') : '',
            genders: geo.genders,
            age_min: geo.age_min,
            age_max: geo.age_max,
            status: prefix === 'xx' ? 'PAUSED' : geo.status.toUpperCase(),
            platform: geo.platform,
            language: geo.language && geo.language.length ? geo.language.join(',') : geo.language,
            adNaming: geo.adNaming,
            autoPlacements: geo.autoPlacements,
            type: geo.adsetTypeToCreate
          };
          if (campaign.budgetType === 'daily adset' || campaign.budgetType === 'lifetime adset') {
            adset.bid_strategy = geo.bidStrategyType;
            adset.bid_amount = geo.bidAmount;
            adset.bid_constraints =
              geo.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS'
                ? { roas_average_floor: geo.roasAverageFloor * 10000 }
                : undefined;
          }
          if (prefix === 'xx') {
            transformedCampaign.campaign.status = 'PAUSED';
          }
          transformedCampaign.campaign.adsets.push(adset);
        }
      });

      result.push(transformedCampaign);
    });

    return result;
  }

  getTargetAndBudget() {
    const targets = this.transformCampaigns();
    let budgetInfo = [];
    targets.forEach(target => budgetInfo.push(target.budget));
    return {
      targets,
      budgetInfo
    };
  }

  isEmptyObject = obj => {
    return !obj || (Object.keys(obj).length === 0 && obj.constructor === Object);
  };

  async submit() {
    if (!this.validateSetup()) {
      return;
    }

    if (!(await this.validateAdsets())) {
      return;
    }
    if (this.props.programOptions.spm_access_block_with_message) {
      window.location.reload();
    }

    const { targets } = this.getTargetAndBudget();
    await this.validateData(targets);
    if (!this.isEmptyObject(this.state.validationErrors)) {
      toast.error(
        `Validation error: Some fields are incorrect or missing. Please review the form and ensure all required information is filled out correctly.`
      );
      console.log(`Validation request has failed: ${JSON.stringify(this.state.validationErrors)}`);
      return;
    }

    if (
      this.isSwag &&
      !this.validate(
        targets.every(camp => camp.campaignCreator),
        'Creator is not selected'
      )
    ) {
      return;
    }
    const isBudgetLimit = (targets, limit = 100) =>
      targets.every(({ budget, currencyMul = 1 }) => budget * currencyMul <= limit);

    let budgetLimit = isBudgetLimit(targets);
    console.dir(targets, { colors: true });
    confirmAlert({
      title: budgetLimit ? 'Create' : 'Warning',
      message: budgetLimit ? 'Are you sure to do this?' : `Are you sure you want to create with budget over 100 USD?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.createCampaigns(targets);
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  upsertSnapshot() {
    this.props.upsertSnapshot(this.state);
  }

  createCampaigns(targets) {
    this.props.createCampaign(targets);
  }

  addCampaign() {
    const campaignList = this.state.campaignList;
    const newCampaign = _.cloneDeep(DEFAULT_CAMPAIGN);
    if (campaignList[0].selectArticleCreativeData) {
      newCampaign.selectArticleCreativeData = campaignList[0].selectArticleCreativeData;
    }
    campaignList.push(newCampaign);
    this.setState({ campaignList });
  }
  copyCampaign(campIdx) {
    const campaignList = this.state.campaignList;
    const camp = _.cloneDeep(campaignList[campIdx]);
    campaignList.push(camp);
    this.setState({ campaignList });
  }
  deleteCampaign(campIdx) {
    const campaignList = this.state.campaignList;
    campaignList.splice(campIdx, 1);
    this.setState({ campaignList });
  }
  addGeo(campIdx) {
    const campaignList = this.state.campaignList;
    const newGeo = _.cloneDeep(DEFAULT_GEO);
    campaignList[campIdx].geoList.push(newGeo);
    this.setState({ campaignList });
  }
  copyGeo(campIdx, idx) {
    const campaignList = this.state.campaignList;
    const geo = _.cloneDeep(campaignList[campIdx].geoList[idx]);
    campaignList[campIdx].geoList.push(geo);
    this.setState({ campaignList });
  }
  deleteGeo(campIdx, idx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList.splice(idx, 1);
    this.setState({ campaignList });
  }

  handleSelectPreset(preset) {
    this.setState({ preset });
  }
  handleUpdatePreset(presetName) {
    this.setState({ presetName });
  }
  validateNumeric(value) {
    value = value.replace(/^[0-]/gi, '');
    return value;
  }

  validateAlphaNumericAccount(event) {
    const allowedKeys = /[A-Za-z0-9_-]/;
    if (!allowedKeys.test(event.key)) {
      event.preventDefault();
    }
  }

  validateAlphaNumericGeo(event) {
    if (!/[a-z]/.test(event.key) || event.target.value.length > 1) {
      event.preventDefault();
    }
  }

  selectArticleCreative(article) {
    let campaignList = this.state.campaignList;
    if (article === '') {
      this.setState({ selectArticleCreativeButton: false });
    } else {
      let articleName = `${article}`;
      getCreativesByArticle(this.state.prefix, articleName).then(res => {
        if (res.length === 0) {
          this.setState({ selectArticleCreativeButton: false });
        } else {
          campaignList.forEach(camp => {
            camp.selectArticleCreativeData = res;
          });
          this.setState({ campaignList, selectArticleCreativeButton: true });
        }
      });
    }
  }

  showArticleCreative(adsetIdx, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].showCreativeBank = true;
    this.setState({ campaignList });
  }

  cancelArticleCreativeSelection(campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].showCreativeBank = false;
    this.setState({ campaignList });
  }

  makeBatch(arr, num) {
    let chunks = [];
    let i = 0;
    while (i < arr.length) {
      chunks.push(arr.slice(i, (i += num)));
    }
    return chunks;
  }
  getDynamicCreatives(data, campIdx, geoIdx) {
    let campaignList = this.state.campaignList;
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }
    data.map(x => {
      campaignList[campIdx].geoList[geoIdx].adsets[0].ads[0].text.push(x.text);
      campaignList[campIdx].geoList[geoIdx].adsets[0].ads[0].title.push(x.title);
      campaignList[campIdx].geoList[geoIdx].adsets[0].ads[0].image.push(x.image);
    });
    campaignList[campIdx].geoList[geoIdx].adsets[0].fromCreativeBank = true;
    return campaignList;
  }
  getSingleCreatives(data, campIdx, geoIdx) {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }
    let campaignList = this.state.campaignList;
    data.map(x => {
      campaignList[campIdx].geoList[geoIdx].adsets[0].ads.push(
        JSON.parse(
          JSON.stringify({
            text: x.text,
            title: x.title,
            image: x.image,
            fromCreativeBank: true
          })
        )
      );
    });
    return campaignList;
  }
  applyArticleCreativeSelection(data, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    if (campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate === 'DYNAMIC') {
      this.getDynamicCreatives(data, campIdx, geoIdx);
    }
    if (campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate === 'SINGLE') {
      this.getSingleCreatives(data, campIdx, geoIdx);
    }
    campaignList[campIdx].geoList[geoIdx].showCreativeBank = false;
    this.setState({ campaignList });
  }

  summaryText(opts, xs) {
    if (!Array.isArray(opts) && !opts.length) {
      return '';
    }
    if (Array.isArray(xs) && xs.length) {
      const names = xs.map(x => {
        const selected = opts.find(opt => opt.id === x.value || opt.value === x.value);
        return selected ? selected.name || selected.label : '';
      });
      return names.join(', ');
    } else if (typeof xs === 'string') {
      const selected = opts.find(opt => opt.value === xs);
      return selected ? selected.label : '';
    } else {
      return '';
    }
  }

  getDestinationAccountOptions() {
    return this.props.accounts
      .map(x => ({
        label: x.desc,
        value: x.id || x.account_id,
        isOptionDisabled: x?.fb_account_status !== 1
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  filterCcList(ccList, filteredCc) {
    const result = [];
    for (const item of ccList) {
      const matchingItem = filteredCc.find(cc => cc.id === (item.value || item));
      if (matchingItem) {
        result.push({
          label: matchingItem.name,
          value: matchingItem.id
        });
      }
    }
    return result;
  }

  async setPreset() {
    try {
      const preset = this.state.presets.find(e => e.name === this.state.preset);
      if (!preset) {
        toast.error('Select preset');
        return;
      }

      const result = await retrievePreset(preset.id, PLATFORMS.FACEBOOK);

      if (!result || result.length === 0 || !result[0].preset.data) {
        toast.error('Could not get preset');
        return;
      }
      this.props.startLoading();
      for (const camp of result[0].preset.data) {
        const acc = getAccount(camp.destinationAccount, this.props.accounts);
        const customConversions = await this.fetchOneConversionListCached(camp.destinationAccount);
        if (!acc?.fb_account_status || acc?.fb_account_status === 1) {
          if (acc?.business_mgr_id && customConversions) {
            const optionalFilter = { filterConverions: false };
            const filteredConversions = await filterConversionsByBmId(
              customConversions,
              acc.business_mgr_id,
              this.props.userMail,
              optionalFilter,
              FB.DYNAMIC_CC_USERS,
              this.isSwag
            );
            camp.geoList.forEach(geo => {
              geo.cc = this.filterCcList(geo.cc, filteredConversions) ?? [];
              geo.geoName = this.updateGeo(geo.countries, geo.geoType);
              if (geo.campaignNote && geo.cc.length <= 0) {
                geo.campaignNote = [];
              }
            });

            if (camp.customConversions) {
              camp.customConversions = filteredConversions;
            }
          } else {
            camp.destinationAccount = undefined;
            camp.account = undefined;
            camp.accountName = undefined;
            camp.geoList.forEach(geo => {
              geo.cc = [];
              geo.geoName = this.updateGeo(geo.countries, geo.geoType);
              if (geo.campaignNote && geo.cc.length <= 0) {
                geo.campaignNote = [];
              }
            });
          }
        }
        if (this.props.sourceData && this.props.sourceData.campaign.adsets.length > 0) {
          camp.geoList?.forEach((geo, index) => {
            if (index < this.props.sourceData.campaign.adsets.length) {
              geo.adsets[0].ads = _.cloneDeep(this.props.sourceData.campaign.adsets[index].ads);
            }
          });
        }
        if (camp.campaignStartTime) {
          camp.campaignStartTime = moment()
            .add(1, 'day')
            .startOf('day');
        }
        if (camp.campaignStopTime) {
          camp.campaignStopTime = undefined;
        }
        if (camp.campaignDailyStartHour) {
          camp.campaignDailyStartHour = undefined;
        }
        if (camp.budgetEndTime) {
          camp.budgetEndTime = undefined;
        }
        if (this.state.campaignList[0].selectArticleCreativeData.length > 0) {
          camp.selectArticleCreativeData = this.state.campaignList[0].selectArticleCreativeData;
        } else {
          camp.selectArticleCreativeData = {};
        }
        if (camp.campaignObjective) {
          camp.campaignObjective =
            camp.campaignObjective.toLowerCase() === 'conversions' ||
            camp.campaignObjective.toLowerCase() === 'outcome_sales'
              ? 'outcome_sales'
              : 'outcome_traffic';
        }
        camp.budget = FB.MIN_BUDGET;
        camp.tempBudget = FB.MIN_BUDGET;
      }
      this.setState({ campaignList: result[0].preset.data });
      this.props.endLoading();
    } catch (error) {
      console.error('An error occurred: get preset', error);
      toast.error('An error occurred. Please try to set preset again.');
      this.props.endLoading();
    }
  }

  clearCampaignProperties(campaign) {
    campaign.article = '';
    campaign.widgetCodeName = '';
    campaign.widgetLanguageCode = '';
    campaign.widgetVersion = '';
    campaign.widget_id = '';
    campaign.widget_path = '';
    campaign.widget_title = '';
    campaign.selectArticleCreativeData = {};
  }
  savePreset(presetName) {
    try {
      const campaignList = _.cloneDeep(this.state.campaignList);
      const userMail = this.props.userMail;

      if (!presetName || !userMail || !campaignList) {
        toast.error('Invalid input for saving preset');
        return;
      }

      const { targets } = this.getTargetAndBudget();
      targets.forEach(target => {
        target.campaign.adsets.forEach(adset => {
          adset.ads.length = 0;
        });
        this.clearCampaignProperties(target);
      });

      campaignList.forEach(campaign => {
        campaign.geoList.forEach(geo => {
          geo.adsetTypeToCreate = 'SINGLE';
          geo.adsets = [
            {
              type: 'SINGLE',
              ads: []
            }
          ];
        });
        this.clearCampaignProperties(campaign);
      });
      const result = { data: campaignList, target: targets };
      createPreset(this.state.presetName, userMail, JSON.stringify(result), PLATFORMS.FACEBOOK).then(() =>
        this.readPresets()
      );
      this.handleSelectPreset(presetName);
      toast.success('Successfully saved preset');
    } catch (error) {
      toast.error('Failed to save preset: ' + error.message);
    }
  }

  async saveAsPreset(presetName) {
    try {
      if (presetName && this.state.preset) {
        const preset = this.state.presets.find(e => e.name === this.state.preset);

        if (!preset) {
          toast.error('Selected preset not found');
          return;
        }

        await updatePreset(preset.id, presetName, PLATFORMS.FACEBOOK);
        await this.readPresets();
        this.handleSelectPreset(presetName);

        toast.success('Successfully saved preset');
      } else {
        toast.error('Saving failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred while saving preset. Please try again.');
    }
  }

  async readPresets() {
    try {
      const result = await getPresets(PLATFORMS.FACEBOOK);

      const userPresets = result.filter(preset => preset.email === this.props.userMail);

      this.setState({ presets: userPresets });
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred while reading presets. Please try again.');
    }
  }

  getPresetOptions() {
    const options = this.state.presets;
    return options.map(x => ({ label: x.name, value: x.name }));
  }

  async deletePreset() {
    try {
      if (this.state.preset) {
        const preset = this.state.presets.find(e => e.name === this.state.preset);

        if (!preset) {
          toast.error('Selected preset not found');
          return;
        }

        await deletePreset(preset.id, PLATFORMS.FACEBOOK);
        await this.readPresets();

        toast.success('Successfully deleted preset');
      } else {
        toast.error('Deleting failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred while deleting preset. Please try again.');
    }
  }

  fetchArticleData = (campIdx, geoIdx) => {
    const widget_id = this.state.widget_id;
    const campaignList = this.state.campaignList;
    const siteList = getPlatformSites(PLATFORMS.FACEBOOK.toLowerCase());
    const site = siteList[Math.floor(Math.random() * siteList.length)].name;
    getArticleData(site, widget_id)
      .then(res => {
        const imgLink = `https://img.${site}/articles/${widget_id}/`;
        const imagesAmount = campaignList[campIdx].geoList[geoIdx].imagesAmount;
        const parsedData = JSON.parse(res.substr(61).slice(0, -1)).blocks.map(block => `${imgLink}${block.image.name}`);
        const linkList = imagesAmount ? parsedData.slice(0, imagesAmount) : parsedData;
        const batchList = this.makeBatch(linkList, 50);
        const adsets = batchList.map(xs => ({
          images: xs,
          titles: [],
          texts: []
        }));
        this.addCreatives(adsets, campIdx, geoIdx);
      })
      .catch(error => {
        console.log(error);
        toast.error('Images not found. Please, try again');
        Sentry.captureException(error);
      });
  };
  updateOneDevice(device, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].device = device;
    // eslint-disable-next-line default-case
    switch (device) {
      case 'Mobile':
        campaignList[campIdx].geoList[geoIdx].mobilePlatforms = FB.MOBILE_PLATFORM_LIST;
        campaignList[campIdx].geoList[geoIdx].platform = 'm';
        break;
      case 'Desktop':
        campaignList[campIdx].geoList[geoIdx].platform = 'd';
        campaignList[campIdx].geoList[geoIdx].mobilePlatforms = [];
        break;
      case 'All':
        campaignList[campIdx].geoList[geoIdx].platform = 'a';
        campaignList[campIdx].geoList[geoIdx].mobilePlatforms = [];
        break;
    }
    let note;
    let ccId = campaignList[campIdx].geoList[geoIdx].cc ? campaignList[campIdx].geoList[geoIdx].cc : [];
    if (typeof ccId === 'string') {
      ccId = ccId.split(',');
    }
    if (ccId.length) {
      note = ccId.map(el => {
        return FB.FB_OPTIONS_NOTE_USERS.includes(this.props.userMail)
          ? this.getOptionsNote(el.value, campaignList[campIdx].geoList[geoIdx], campaignList[campIdx].geoList[geoIdx])
          : this.getInternalNote(
              el.value || el,
              campaignList[campIdx].geoList[geoIdx].platform,
              campaignList[campIdx].geoList[geoIdx].mobilePlatforms,
              campaignList[campIdx].customConversions
            );
      });
    }
    if (campaignList[campIdx].campaignObjective === 'outcome_traffic') {
      note = this.getOneNote(
        campaignList[campIdx].geoList[geoIdx].platform,
        campaignList[campIdx].geoList[geoIdx].mobilePlatforms
      );
    }
    if (note && note.length) {
      campaignList[campIdx].geoList[geoIdx].campaignNote = note;
    }
    this.setState({ campaignList });
  }
  getCcOptions(xs) {
    return xs.map(el => ({
      label: el.name,
      value: el.id
    }));
  }

  getConversionList(bidStrategyType, campIdx) {
    if (bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS') {
      if (this.isSwag) {
        return [FB.CONVS.PURCHASE, FB.CONVS.PURCHASE_CAPI, FB.CONVS.REVB];
      } else {
        return [FB.CONVS.PURCHASE_CAPI];
      }
    }

    const conversionList = this.getCcOptions(this.state.campaignList[campIdx].customConversions);
    const uniqueConversions = [...new Set(conversionList)];
    return uniqueConversions;
  }

  updateQs(qs, campIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx] = { ...campaignList[campIdx], qs };
    this.setState({ campaignList });
  }
  renderGeo(geo, geoIdx, campIdx) {
    const { isSwag } = this;
    return (
      <React.Fragment key={'Adset ' + geoIdx}>
        <Box
          key={'adsetBox' + geoIdx}
          boxShadow={3}
          bgcolor={'#f0f7fa'}
          marginTop={'10px'}
          style={{ width: '100%', height: '100%', margin: '4px' }}
        >
          <StyledAdsetToolbar container direction={'row'} alignItems={'center'} key={'adsetGridContainer ' + geoIdx}>
            <Grid item sm={8}>
              <StyledTypography variant={'subtitle2'} color={'secondary'}>
                Adset {geoIdx + 1}
              </StyledTypography>
            </Grid>
            <Grid item sm={4} align={'right'}>
              <IconButton size="small" color={'secondary'} onClick={() => this.addGeo(campIdx)}>
                <AddIcon fontSize="small" />
              </IconButton>
              {this.state.campaignList[campIdx].geoList.length < 2 ? null : (
                <IconButton size="small" color="secondary" onClick={() => this.deleteGeo(campIdx, geoIdx)}>
                  <DeleteForeverRoundedIcon />
                </IconButton>
              )}
              <IconButton size="small" color={'secondary'} onClick={() => this.copyGeo(campIdx, geoIdx)}>
                <FileCopyIcon fontSize="small" />
              </IconButton>
              {geo.toggleGeo ? (
                <IconButton size="small" color={'secondary'} onClick={() => this.handleGeoDropdown(campIdx, geoIdx)}>
                  <ExpandLessIcon fontSize={'small'} />
                </IconButton>
              ) : (
                <IconButton size="small" color="secondary" onClick={() => this.handleGeoDropdown(campIdx, geoIdx)}>
                  <KeyboardArrowDownIcon fontSize={'small'} />
                </IconButton>
              )}
            </Grid>

            {!geo.toggleGeo && (
              <Grid container direction={'row'} item sm={12}>
                {geo.site_code ? (
                  <Typography variant={'subtitle2'} color={'secondary'}>
                    {geo.site_code.toUpperCase()} |
                  </Typography>
                ) : null}
                {geo.mobilePlatforms && geo.mobilePlatforms.length ? (
                  <Typography variant={'subtitle2'} color={'secondary'}>
                    {geo.mobilePlatforms.toString()} |
                  </Typography>
                ) : null}
                {geo.geoName ? (
                  <Typography variant={'subtitle2'} color={'secondary'}>
                    {geo.geoName} |
                  </Typography>
                ) : null}
                <Typography variant={'subtitle2'} color={'secondary'}>
                  {this.summaryText(this.state.campaignList[campIdx].customConversions, geo.cc || geo.cc.value)} |
                </Typography>
                <Typography variant={'subtitle2'} color={'secondary'}>
                  {this.state.campaignList[campIdx].campaignObjective}
                </Typography>
                {geo.language && (
                  <Typography variant={'subtitle2'} color={'secondary'}>
                    | {this.summaryText(this.state.languageList, geo.language)}
                  </Typography>
                )}
              </Grid>
            )}
          </StyledAdsetToolbar>
          {geo.toggleGeo ? (
            <Grid container direction={'row'} alignItems={'center'} sx={{ padding: '10px 10px' }} spacing={1}>
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Domain
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  isClearable
                  onChange={x => this.updateOneDomain(x?.value, campIdx, geoIdx)}
                  options={this.state.campaignList[campIdx].siteList}
                  value={getValue(this.state.campaignList[campIdx].siteList, geo.site_code)}
                />
              </Grid>
              <Grid direction={'row'} container item xs={12} sm={4}>
                <Typography gutterBottom variant={'subtitle2'}>
                  Device
                </Typography>
                <Tooltip title="All- Campaigns will target Mobile, Desktop, and Tablet together" placement="right">
                  <Typography gutterBottom variant="subtitle2" color={'primary'} sx={{ marginLeft: '10px' }}>
                    ?
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  onChange={x => this.updateOneDevice(x?.value, campIdx, geoIdx)}
                  options={this.getDeviceList()}
                  value={geo.device ? { label: geo.device, value: geo.device } : null}
                />
              </Grid>
              <Grid container item sm={5} spacing={2}>
                <Grid container direction={'row'} item sm={6} spacing={2}>
                  <Grid item sm={3}>
                    <input
                      style={{ padding: '7px', marginLeft: '5px' }}
                      type="checkbox"
                      onChange={() => this.updateMobilePlatforms(campIdx, geoIdx, 'Android')}
                      checked={geo.mobilePlatforms.includes('Android')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Android
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction={'row'} item sm={6} spacing={2}>
                  <Grid item sm={3}>
                    <input
                      style={{ padding: '7px', marginLeft: '5px' }}
                      type="checkbox"
                      onChange={() => this.updateMobilePlatforms(campIdx, geoIdx, 'iOS')}
                      checked={geo.mobilePlatforms.includes('iOS')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      IOS
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {(this.state.campaignList[campIdx].budgetType === 'daily adset' ||
                this.state.campaignList[campIdx].budgetType === 'lifetime adset') && (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant="subtitle2">
                      Bid strategy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={geo.bidStrategyType === 'LOWEST_COST_WITHOUT_CAP' ? 8 : 6}>
                    <Select
                      isClearable
                      value={getSelectValue(FB.BID_STRATEGY_TYPES, geo.bidStrategyType)}
                      onChange={x => this.updateBidStrategyType(x?.value, campIdx, geoIdx)}
                      options={FB.BID_STRATEGY_TYPES}
                    />
                  </Grid>
                  {geo.bidStrategyType === 'LOWEST_COST_WITH_BID_CAP' || geo.bidStrategyType === 'COST_CAP' ? (
                    <>
                      <Grid item xs={6} sm={2}>
                        <input
                          style={{ padding: '7px', marginBottom: '2px' }}
                          type="number"
                          name="bid_amount"
                          step={0.01}
                          value={geo.bidAmount}
                          onChange={e => this.updateBidAmount(e.target.value, campIdx, geoIdx)}
                        />
                      </Grid>
                    </>
                  ) : null}
                  {geo.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS' ? (
                    <>
                      <Grid item xs={6} sm={2}>
                        <input
                          style={{ padding: '7px', marginBottom: '2px' }}
                          type="number"
                          name="roas_average_floor"
                          step={0.001}
                          min="0.001"
                          value={geo.roasAverageFloor}
                          onChange={e => this.updateROASAverageFloor(e.target.value, campIdx, geoIdx)}
                        />
                      </Grid>
                    </>
                  ) : null}
                </>
              )}
              {this.state.campaignList[campIdx].campaignObjective === 'outcome_sales' && (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      CC
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      isMulti
                      onChange={xs => this.handleOneCcSelect(xs, campIdx, geoIdx)}
                      options={this.getConversionList(
                        this.checkBudgetType(this.state.campaignList[campIdx].budgetType)
                          ? this.state.campaignList[campIdx].bidStrategyType
                          : geo.bidStrategyType,
                        campIdx
                      )}
                      value={geo.cc}
                    />
                  </Grid>
                  {geo.campaignNote && geo.campaignNote.length > 1 ? (
                    geo.campaignNote.map((note, noteIdx) => (
                      <React.Fragment key={noteIdx}>
                        <Grid item xs={12} sm={4}>
                          <Typography gutterBottom variant={'subtitle2'}>
                            Campaign Note
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <input
                            style={{ padding: '7px', marginBottom: '2px' }}
                            type="text"
                            name="campaignNote"
                            value={note}
                            onChange={e => this.updateOneCampaignNote(e.target.value, campIdx, geoIdx, noteIdx)}
                          />
                        </Grid>
                      </React.Fragment>
                    ))
                  ) : (
                    <>
                      <Grid item xs={12} sm={4}>
                        <Typography gutterBottom variant={'subtitle2'}>
                          Campaign Note
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <input
                          style={{ padding: '7px', marginBottom: '2px' }}
                          type="text"
                          name="campaignNote"
                          value={geo.campaignNote}
                          onChange={e => this.updateOneCampaignNote(e.target.value, campIdx, geoIdx, 0)}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
              {this.state.campaignList[campIdx].campaignObjective === 'outcome_traffic' && (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Campaign Note
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <input
                      style={{ padding: '7px', marginBottom: '2px' }}
                      type="text"
                      name="campaignNote"
                      value={geo.campaignNote}
                      onChange={e => this.updateOneCampaignNote(e.target.value, campIdx, geoIdx)}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Targeted geo
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8}>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={xs => this.updateCountries(getOnlyValue(xs), campIdx, geoIdx)}
                  options={this.countryList}
                  value={getMultiSelectValue(this.countryList, geo.countries)}
                />
              </Grid>
              <Grid item sm={4}></Grid>
              <Grid container item sm={8} spacing={2} alignItems={'center'}>
                <Grid container direction={'row'} item sm={6} spacing={1}>
                  <Grid item>
                    <input
                      style={{ padding: '7px', marginLeft: '5px' }}
                      type="checkbox"
                      onChange={() => this.updateCountriesGroup('include', campIdx, geoIdx)}
                      checked={geo.geoType === 'include'}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Include
                    </Typography>
                  </Grid>
                  <Grid item sm={1}>
                    <Tooltip title={`The campaign will include the geos you target`} placement="right">
                      <Typography gutterBottom variant="subtitle2" color={'primary'} sx={{ marginLeft: '10px' }}>
                        ?
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container direction={'row'} item sm={6} spacing={1}>
                  <Grid item>
                    <input
                      style={{ padding: '7px', marginLeft: '5px' }}
                      type="checkbox"
                      onChange={() => this.updateCountriesGroup('exclude', campIdx, geoIdx)}
                      checked={geo.geoType === 'exclude'}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Exclude
                    </Typography>
                  </Grid>
                  <Grid item sm={1}>
                    <Tooltip title={`The campaign will exclude the geos you target`} placement="right">
                      <Typography gutterBottom variant="subtitle2" color={'primary'} sx={{ marginLeft: '10px' }}>
                        ?
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              {isSwag ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant="subtitle2">
                      Geo name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <input
                      style={{ padding: '7px', marginBottom: '2px' }}
                      type="text"
                      name="geoName"
                      value={geo.geoName}
                      onChange={e => this.updateGeoName(e.target.value, campIdx, geoIdx)}
                      onKeyDown={event => this.validateAlphaNumericGeo(event)}
                    />
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Genders
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  onChange={x => this.updateOneGender(x.value, campIdx, geoIdx)}
                  options={FB.GENDERS_LIST}
                  value={getSelectValue(FB.GENDERS_LIST, geo.genders)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Age
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  onChange={x => this.updateOneMinAge(x?.value, campIdx, geoIdx)}
                  options={geo.ageList.minAge}
                  isClearable
                  value={getSelectValue(geo.ageList.minAge, geo.age_min)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  onChange={x => this.updateOneMaxAge(x?.value, campIdx, geoIdx)}
                  options={geo.ageList.maxAge}
                  isClearable
                  value={getSelectValue(geo.ageList.maxAge, geo.age_max)}
                />
              </Grid>
              {isSwag && (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant="subtitle2">
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      value={getSelectValue(FB.STATUS, geo.status)}
                      onChange={x => this.updateStatus(x.value, campIdx, geoIdx)}
                      options={FB.STATUS}
                    />
                  </Grid>
                </>
              )}
              {this.state.widget_id && !this.props.sourceData && isSwag ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant="subtitle2">
                      Images amount
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <input
                      style={{ padding: '7px', marginBottom: '2px' }}
                      type="number"
                      name="images_amount"
                      step={1}
                      min="1"
                      max="50"
                      value={geo.imagesAmount}
                      onChange={e => this.updateImagesAmount(e.target.value, campIdx, geoIdx)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      component="span"
                      onClick={() => this.fetchArticleData(campIdx, geoIdx)}
                    >
                      Retrieve images
                    </Button>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Select languages
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  options={this.state.languageList}
                  value={getMultiSelectValue(this.state.languageList, geo.language)}
                  onChange={xs =>
                    this.updateLanguages(
                      xs.map(x => x.value),
                      campIdx,
                      geoIdx
                    )
                  }
                />
              </Grid>
              <Grid item container direction={'row'} xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Ad naming
                </Typography>
                <Tooltip
                  title={`Unique- each ad will get a unique UTM.\n
            Same- all the ads will get the same UTM`}
                  placement="right"
                >
                  <Typography gutterBottom variant="subtitle2" color={'primary'} sx={{ marginLeft: '10px' }}>
                    ?
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  onChange={x => this.updateAdNaming(x.value, campIdx, geoIdx)}
                  options={FB.AD_NAMING_LIST}
                  value={getSelectValue(FB.AD_NAMING_LIST, geo.adNaming)}
                />
              </Grid>
              <Grid container item direction="row">
                <Grid item sm={4}>
                  <Typography gutterBottom variant="subtitle2">
                    Auto placements
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <input
                    style={{ padding: '7px', marginLeft: '5px' }}
                    type="checkbox"
                    onChange={() => this.toggleAutoPlacements(campIdx, geoIdx)}
                    checked={geo.autoPlacements}
                  />
                </Grid>
              </Grid>
              {this.isSwag && (
                <Grid container item direction="row">
                  <Grid item sm={4}>
                    <Typography gutterBottom variant="subtitle2">
                      Add parameter subid4
                    </Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <input
                      style={{ padding: '7px', marginLeft: '5px' }}
                      type="checkbox"
                      onChange={() => this.addParamSub(campIdx, geoIdx)}
                      checked={geo.paramSub}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Ad type
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  style={{ marginTop: '1px' }}
                  fullWidth
                  value={getSelectValue(isSwag ? FB.SWAG_AD_TYPE : FB.AD_TYPE, geo.adsetTypeToCreate)}
                  options={isSwag ? FB.SWAG_AD_TYPE : FB.AD_TYPE}
                  onChange={x => this.changeAdTypeToCreate(x.value, campIdx, geoIdx)}
                />
              </Grid>
            </Grid>
          ) : null}
        </Box>
        <Grid
          container
          direction="row"
          alignItems={'center'}
          sx={{ padding: '10px 10px' }}
          spacing={1}
          key={'adsetGridContainer ' + campIdx + geoIdx}
        >
          <AdsetsBuilder
            key={campIdx + geoIdx}
            geoIdx={geoIdx}
            campIdx={campIdx}
            adsets={geo.adsets}
            showArticleCreative={adIdx => this.showArticleCreative(adIdx, campIdx, geoIdx)}
            modifyAdsets={ad => this.modifyAdsets(ad, campIdx, geoIdx)}
            adsetTypeToCreate={geo.adsetTypeToCreate}
            addAdset={maybeAdset => this.addAdset(maybeAdset, campIdx, geoIdx)}
            deleteAdset={adIdx => this.deleteAdset(adIdx, campIdx, geoIdx)}
            modifyAdset={(i, adset) => this.modifyAdset(i, adset, campIdx, geoIdx)}
            populateAllText={adset => this.populateAllText(adset, campIdx, geoIdx)}
            videoCreatives={this.state.videoCreatives}
            onAddImage={(adIdx, img) => this.onAddImage(adIdx, img, campIdx, geoIdx)}
            onAddVideo={(adIdx, video) => this.onAddVideo(adIdx, video, campIdx, geoIdx)}
            onAddDynamicImage={(adsetIdx, img) => this.onAddDynamicImage(adsetIdx, img, campIdx, geoIdx)}
            widgetId={this.state.widget_id}
            spmCreativeBankAccess={this.props.spmCreativeBankAccess}
            spmUploadAccess={this.props.spmCreativeBankAccess}
            spmReviewAccess={this.props.spmReviewAccess}
            spmUploadDirectly={this.props.programOptions.spm_upload_directly_access}
            isCreativesReviewer={this.props.isCreativesReviewer}
            platform={this.props.platform}
            handleAdsDropdown={() => this.handleAdsDropdown(campIdx, geoIdx)}
            toggleAd={geo.toggleAd}
            selectArticleCreativeButton={this.state.selectArticleCreativeButton}
          />
        </Grid>
        <Grid
          container
          direction="row"
          alignItems={'center'}
          sx={{ padding: '10px 10px' }}
          spacing={1}
          key={'creativeGridContainer ' + campIdx + geoIdx}
        >
          {geo.showCreativeBank ? (
            <CreativeBank
              key={campIdx + geoIdx}
              geoIdx={geoIdx}
              data={this.filteredCreatives(this.state.campaignList[campIdx].selectArticleCreativeData)}
              onSave={data => this.applyArticleCreativeSelection(data, campIdx, geoIdx)}
              onClose={() => this.cancelArticleCreativeSelection(campIdx, geoIdx)}
              adsets={geo.adsets}
            />
          ) : null}
        </Grid>
      </React.Fragment>
    );
  }

  renderCampaign(camp, campIdx) {
    const { isSwag } = this;
    return (
      <React.Fragment key={'renderCamp ' + campIdx}>
        <Box
          key={'campaignBox' + campIdx}
          boxShadow={3}
          bgcolor={'#eef4ff'}
          marginTop={'10px'}
          style={{ width: '100%', height: '100%', margin: '4px' }}
        >
          <StyledContainer container direction={'row'} alignItems={'center'} key={'campaignGridContainer ' + campIdx}>
            <Grid item sm={8}>
              <StyledTypography variant={'subtitle2'} color={'secondary'}>
                Campaign {campIdx + 1}
              </StyledTypography>
            </Grid>
            <Grid item sm={4} align={'right'}>
              <IconButton size="small" color={'secondary'} onClick={() => this.addCampaign()}>
                <AddIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" color={'secondary'} onClick={() => this.copyCampaign(campIdx)}>
                <FileCopyIcon fontSize="small" />
              </IconButton>
              {this.state.campaignList.length < 2 ? null : (
                <IconButton size="small" color="secondary" onClick={() => this.deleteCampaign(campIdx)}>
                  <DeleteForeverRoundedIcon />
                </IconButton>
              )}
              {camp.toggleCampaign ? (
                <IconButton size="small" color={'secondary'} onClick={() => this.handleCampDropdown(campIdx)}>
                  <ExpandLessIcon fontSize={'small'} />
                </IconButton>
              ) : (
                <IconButton size="small" color="secondary" onClick={() => this.handleCampDropdown(campIdx)}>
                  <KeyboardArrowDownIcon fontSize={'small'} />
                </IconButton>
              )}
            </Grid>
            {!camp.toggleCampaign && (
              <Grid container direction={'row'} item sm={12}>
                {camp.campaignObjective ? (
                  <Typography variant={'subtitle2'} color={'secondary'}>
                    {camp.campaignObjective} |
                  </Typography>
                ) : null}
                {camp.budgetType ? (
                  <Typography variant={'subtitle2'} color={'secondary'}>
                    {this.summaryText(FB.BUDGET_TYPE, camp.budgetType)} |
                  </Typography>
                ) : null}
                <Typography variant={'subtitle2'} color={'secondary'}>
                  budget {camp.budget}
                </Typography>
              </Grid>
            )}
          </StyledContainer>

          {camp.toggleCampaign ? (
            <Grid container direction={'row'} alignItems={'center'} sx={{ padding: '10px 10px' }} spacing={1}>
              <Grid container item xs={12} sm={4} direction={'row'}>
                <Typography gutterBottom variant="subtitle2">
                  Destination account
                </Typography>
                <Tooltip title="The account you will upload the campaign to" placement="right">
                  <Typography gutterBottom variant="subtitle2" color={'primary'} sx={{ marginLeft: '10px' }}>
                    ?
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  onChange={x => this.updateDestinationAccount(x.value, campIdx)}
                  options={this.state.accounts}
                  isOptionDisabled={option => option.isOptionDisabled}
                  placeholder={
                    isSwag && !this.state.widget_id ? 'Select widget to be able to choose account' : 'Select...'
                  }
                  isDisabled={isSwag && !this.state.widget_id}
                  value={getValue(this.state.accounts, camp.destinationAccount)}
                />
              </Grid>
              {isSwag ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant="subtitle2">
                      Account name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <input
                      style={{ padding: '7px', marginBottom: '2px' }}
                      type="text"
                      name="accountName"
                      value={camp.accountName}
                      onChange={e => this.updateOneAccountName(e.target.value, campIdx)}
                      onKeyDown={event => this.validateAlphaNumericAccount(event)}
                      readOnly={isSwag}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Campaign objective
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  value={getSelectValue(FB.CAMPAIGN_OBJECTIVES, camp.campaignObjective)}
                  onChange={x => this.updateObjective(x?.value, campIdx)}
                  options={FB.CAMPAIGN_OBJECTIVES}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Budget
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  value={{ label: camp.budgetType, value: camp.budgetType }}
                  onChange={x => this.updateBudgetType(x?.value, campIdx)}
                  options={FB.BUDGET_TYPE}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <input
                  style={{ padding: '7px', marginBottom: '2px' }}
                  type="number"
                  name="budget"
                  min={15}
                  value={camp.tempBudget}
                  onChange={e => this.handleBudgetChange(e, campIdx)}
                  onBlur={() => this.handleBudgetBlur(campIdx)}
                />
              </Grid>
              {this.state.destAccCurrency && (
                <Grid item xs={12} sm={2}>
                  <Typography gutterBottom variant="subtitle2">
                    {this.state.destAccCurrency}
                  </Typography>
                </Grid>
              )}
              {(camp.budgetType === 'lifetime adset' || camp.budgetType === 'lifetime campaign') && (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant="subtitle2">
                      Adset end time
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <DatePicker
                      selected={camp.budgetEndTime ? new Date(camp.budgetEndTime) : ''}
                      onChange={x => this.updateBudgetEndTime(x, campIdx)}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      minDate={
                        camp.campaignStartTime
                          ? moment(camp.campaignStartTime)
                              .add(1, 'day')
                              .toDate()
                          : moment()
                              .add(1, 'day')
                              .toDate()
                      }
                    />
                  </Grid>
                  {!isSwag && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <Typography gutterBottom variant="subtitle2">
                          Campaign daily start hour
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Select
                          onChange={x => this.updateCampaignDailyStartHour(x?.value, campIdx)}
                          options={this.getHours()}
                          isClearable
                          value={
                            camp.campaignDailyStartHour
                              ? { label: camp.campaignDailyStartHour, value: camp.campaignDailyStartHour }
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography gutterBottom variant="subtitle2">
                          Campaign stop hours
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Select
                          onChange={x => this.updateCampaignDailyStopHour(x?.value, campIdx)}
                          options={this.getHours()}
                          isClearable
                          value={
                            camp.campaignDailyStopHour
                              ? { label: camp.campaignDailyStopHour, value: camp.campaignDailyStopHour }
                              : null
                          }
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}

              {camp.currency && (
                <Grid item xs={12} sm={4}>
                  <Typography gutterBottom variant="subtitle2">
                    {camp.currency}
                  </Typography>
                </Grid>
              )}
              {this.checkBudgetType(camp.budgetType) && (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant="subtitle2">
                      Bid strategy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={camp.bidStrategyType === 'LOWEST_COST_WITHOUT_CAP' ? 8 : 6}>
                    <Select
                      onChange={x => this.updateBidStrategyType(x?.value, campIdx)}
                      options={FB.BID_STRATEGY_TYPES}
                      isClearable
                      value={getSelectValue(FB.BID_STRATEGY_TYPES, camp.bidStrategyType)}
                    />
                  </Grid>
                  {camp.bidStrategyType === 'LOWEST_COST_WITH_BID_CAP' || camp.bidStrategyType === 'COST_CAP' ? (
                    <Grid item xs={6} sm={2}>
                      <input
                        style={{ padding: '7px', marginBottom: '2px' }}
                        type="number"
                        name="bid_amount"
                        step={0.01}
                        value={camp.bidAmount}
                        onChange={e => this.updateBidAmount(e.target.value, campIdx)}
                      />
                    </Grid>
                  ) : null}
                  {camp.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS' ? (
                    <Grid item xs={6} sm={2}>
                      <input
                        style={{ padding: '7px', marginBottom: '2px' }}
                        type="number"
                        name="roas_average_floor"
                        step={0.001}
                        min="0.001"
                        value={camp.roasAverageFloor}
                        onChange={e => this.updateROASAverageFloor(e.target.value, campIdx)}
                      />
                    </Grid>
                  ) : null}
                </>
              )}
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Campaign start date
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <DatePicker
                  selected={camp.campaignStartTime ? new Date(camp.campaignStartTime) : ''}
                  onChange={x => this.updateCampaignStartTime(x, campIdx)}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  minDate={moment().toDate()}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Campaign stop date
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <DatePicker
                  selected={camp.campaignStopTime ? new Date(camp.campaignStopTime) : ''}
                  onChange={x => this.updateCampaignStopTime(x, campIdx)}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  minDate={
                    camp.campaignStartTime
                      ? moment(camp.campaignStartTime)
                          .add(1, 'day')
                          .toDate()
                      : moment()
                          .add(1, 'day')
                          .toDate()
                  }
                />
              </Grid>
              <Grid container item direction="row">
                <Grid item sm={4}>
                  <Typography gutterBottom variant="subtitle2">
                    Multi-advertiser ads
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <input
                    style={{ padding: '7px', marginLeft: '5px' }}
                    type="checkbox"
                    onChange={() => this.toggleMultiAdvertiser(campIdx)}
                    checked={camp.multi_advertiser}
                  />
                </Grid>
              </Grid>
              <Grid container item direction="row">
                <Grid item sm={4}>
                  <Typography gutterBottom variant="subtitle2">
                    Text generation
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <input
                    style={{ padding: '7px', marginLeft: '5px' }}
                    type="checkbox"
                    onChange={() => this.toggleTextGeneration(campIdx)}
                    checked={camp.text_generation}
                  />
                </Grid>
              </Grid>
              {this.isSwag && (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Query parameters
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Tooltip title={camp.tooltips}>
                      <input
                        style={{ padding: '7px', marginBottom: '2px' }}
                        type="text"
                        name="freeText"
                        value={camp.qs}
                        onChange={e => this.updateQs(e.target.value, campIdx)}
                      />
                    </Tooltip>
                  </Grid>
                </>
              )}
            </Grid>
          ) : null}
        </Box>
        {camp.geoList.map((geo, geoIdx) => this.renderGeo(geo, geoIdx, campIdx))}
      </React.Fragment>
    );
  }
  render() {
    const { isSwag } = this;
    return (
      <>
        <PresetButtonGroup
          setPreset={() => this.setPreset()}
          savePreset={() => this.savePreset(this.state.presetName)}
          saveAsPreset={() => this.saveAsPreset(this.state.presetName)}
          deletePreset={() => this.deletePreset()}
          handleUpdatingPreset={value => this.handleUpdatePreset(value)}
          presetName={this.state.presetName}
          platform={PLATFORMS.FACEBOOK}
          preset={this.state.preset}
          getPresetOptions={() => this.getPresetOptions()}
          handleSelectPreset={value => this.handleSelectPreset(value)}
        />
        <Prefix
          platform={this.props.platform}
          value={{ label: this.state.prefix, value: this.state.prefix }}
          onChange={e => this.updatePrefix(e.value)}
          options={makeSelectOptions(this.props.programOptions?.prefix)}
        />

        {this.state.campaignCreator && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant="subtitle2">
                Creator
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                value={getSelectValue(this.props.creatorsList, this.state.campaignCreator)}
                onChange={x => this.updateCreator(x.value)}
                options={this.props.creatorsList}
              />
            </Grid>
          </>
        )}
        <WidgetSelector
          modify={(name, code, version) => this.updateWidgetFields(name, code, version)}
          platform={PLATFORMS.FACEBOOK}
          articleList={this.props.articleList}
          block={isSwag ? FB.ARTICLE_BLOCK_SWAG : FB.ARTICLE_BLOCK_B2B}
          options={this.state}
          programOptions={this.props.programOptions}
          allowedVersions={this.getAllowedWidgetVersions()}
          triggerSelectorUpdate={this.state.triggerSelectorUpdate}
        />
        {isSwag && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant="subtitle2">
                Website URL
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <input
                style={{ padding: '7px', marginBottom: '2px' }}
                type="text"
                name="website"
                value={this.state.website}
                onChange={e => this.updateWebsite(e.target.value)}
              />
            </Grid>
            {this.props.task === TASKS.CREATE ? (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography gutterBottom variant="subtitle2">
                    Tags
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Select
                    value={getMultiSelectValue(GLOBAL.TAGS, this.state.tags)}
                    onChange={xs => this.updateTags(getOnlyValue(xs))}
                    options={GLOBAL.TAGS}
                    components={{ MultiValue: CustomMultiValue }}
                    isClearable
                    isMulti
                  />
                </Grid>
              </>
            ) : null}
          </>
        )}
        {this.state.campaignList.map((camp, index) => this.renderCampaign(camp, index))}
        <Grid item xs={4} sm={4}>
          <Button variant="outlined" fullWidth color="primary" onClick={() => this.upsertSnapshot()}>
            {this.props.loadedFromSnapshot ? 'Update' : 'Save'} snapshot
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
            {this.getSubmitButtonText()}
          </Button>
        </Grid>
      </>
    );
  }

  async addCreatives(adsets, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    if (campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate === 'DYNAMIC') {
      const newAdsets = adsets.map(() => ({
        type: campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate,
        ads: [{ image: [], text: [], title: [] }]
      }));
      campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], adsets: newAdsets };
      this.setState({ campaignList }, async () => {
        for (let adsetIndex = 0; adsetIndex < adsets.length; adsetIndex++) {
          const adset = adsets[adsetIndex];
          for (let imageIndex = 0; imageIndex < adset.images.length; imageIndex++) {
            const img = adset.images[imageIndex];
            const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
            blob.name = img.split('/').pop();

            await this.onAddDynamicImage(adsetIndex, { target: { files: [blob] } }, campIdx, geoIdx);

            const validatedAdset = campaignList[campIdx].geoList[geoIdx].adsets[adsetIndex];

            validatedAdset.ads[0].title = adset.titles;
            validatedAdset.ads[0].text = adset.texts;
            this.modifyAdset(adsetIndex, validatedAdset, campIdx, geoIdx);
          }
        }
      });
    } else {
      if (this.props.creative) {
        const ads = [];
        for (const x of adsets) {
          for (const text of x.texts) {
            for (const title of x.titles) {
              for (const image of x.images) {
                ads.push({ title, text, image });
              }
            }
          }
        }
        const newAdsets = [
          {
            type: campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate,
            ads: []
          }
        ];
        const adsetIndex = 0;

        this.setState({ adsets: newAdsets }, async () => {
          for (const [adIndex, ad] of ads.entries()) {
            const img = ad.image;
            const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
            blob.name = img.split('/').pop();
            await this.onAddImage(adIndex, { target: { files: [blob] } }, adsetIndex, geoIdx);
            const validatedAdset = campaignList[campIdx].geoList[geoIdx].adsets[adsetIndex];
            validatedAdset.ads[adIndex].title = ad.title;
            validatedAdset.ads[adIndex].text = ad.text;
            this.modifyAdset(adsetIndex, validatedAdset, campIdx, geoIdx);
          }
        });
      } else {
        const newAdsets = adsets.map(() => ({
          type: campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate,
          ads: []
        }));
        this.setState({ adsets: newAdsets }, async () => {
          for (let adsetIndex = 0; adsetIndex < adsets.length; adsetIndex++) {
            const adset = adsets[adsetIndex];
            for (let imageIndex = 0; imageIndex < adset.images.length; imageIndex++) {
              const img = adset.images[imageIndex];
              const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
              blob.name = img.split('/').pop();
              await this.onAddImage(adsetIndex, { target: { files: [blob] } }, campIdx, geoIdx);
              const validatedAdset = campaignList[campIdx].geoList[geoIdx].adsets[adsetIndex];
              this.modifyAdset(adsetIndex, validatedAdset, campIdx, geoIdx);
            }
          }
        });
      }
    }
  }

  async addCreativesFromFbLibrary(adsets, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    const newAdsets = [
      {
        type: campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate,
        ads: []
      }
    ];
    const adsetIndex = 0;
    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], adsets: newAdsets };
    this.setState({ campaignList }, async () => {
      for (const [adIndex, ad] of adsets[adsetIndex].ads.entries()) {
        const img = ad.image;
        const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
        blob.name = img.split('/').pop();
        await this.onAddImage(adsetIndex, { target: { files: [blob] } }, campIdx, geoIdx);

        const validatedAdset = campaignList[campIdx].geoList[geoIdx].adsets[adsetIndex];
        validatedAdset.ads[adIndex].title = ad.title;
        validatedAdset.ads[adIndex].text = ad.text;
        this.modifyAdset(adsetIndex, validatedAdset, campIdx, geoIdx);
      }
    });
  }

  changeAdTypeToCreate(adsetTypeToCreate, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate = adsetTypeToCreate;
    if (adsetTypeToCreate === 'SINGLE' || adsetTypeToCreate === 'SINGLE_VIDEO') {
      campaignList[campIdx].geoList[geoIdx].adsets = [
        {
          type: adsetTypeToCreate,
          ads: []
        }
      ];
    }
    if (adsetTypeToCreate === 'DYNAMIC') {
      campaignList[campIdx].geoList[geoIdx].adsets = [
        {
          type: adsetTypeToCreate,
          ads: [
            {
              image: [],
              text: [],
              title: []
            }
          ]
        }
      ];
    }

    campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], adsetTypeToCreate };
    this.setState({ campaignList });
  }

  onAddDynamicImage(adIdx, event, campIdx, geoIdx) {
    const images = event;
    const errs = [];
    const warnings = [];
    const campaignList = this.state.campaignList;
    if (images.length > 10) {
      const msg = 'Up to 10 images can be uploaded at a time';
      console.log(msg);
      return toast.error(msg);
    }

    const formData = new FormData();

    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        images.forEach((file, i) => {
          let filename_truncated = truncateFileName(file.name);

          if (!validateAndPrepareImage(file, filename_truncated, dimensions[i], adIdx, 'FB', warnings, errs)) {
            return;
          }

          formData.append(i, file);
        });
        return;
      })
      .then(() => {
        // FIXME: here we did not abort if errors are present
        // is this intended?
        displayWarnings(errs, warnings);

        const options = {
          minWidth: FB.MIN_WIDTH,
          minHeight: FB.MIN_HEIGHT,
          noDownscale: true
        };
        return storeFiles(formData, options);
      })
      .then(filenames => {
        const adset = campaignList[campIdx].geoList[geoIdx].adsets[adIdx];
        adset.ads[0].image = adset.ads[0].image.concat(filenames);
        this.modifyAdset(adIdx, adset, campIdx, geoIdx);
      })
      .catch(error => {
        console.log(error);
        toast.error(error);
        Sentry.captureException(error);
      });
  }

  onAddImage(adIdx, event, campIdx, geoIdx) {
    const images = Array.from(event.target.files);

    return processFacebookImages(images, adIdx, storeFiles, (index, filenames) => {
      this.updateAdsetWithImages(index, filenames, campIdx, geoIdx);
    });
  }

  onAddVideo(adIdx, event, campIdx, geoIdx) {
    const videos = Array.from(event.target.files);
    return processFacebookVideos(videos, adIdx, storeVideos, (index, filenames) => {
      this.updateAdsetWithVideos(index, filenames, campIdx, geoIdx);
    });
  }

  updateAdsetWithImages(adIdx, images, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    if (images) {
      const adsets = campaignList[campIdx].geoList[geoIdx].adsets;
      const adset = campaignList[campIdx].geoList[geoIdx].adsets[adIdx];
      const ads = images.map(image => {
        return { image: getFullPath(image), text: '', title: '' };
      });
      let adsetLength = adset.ads.length;
      const allAds = adsetLength + ads.length;
      let adsAmount = FB.ADSET_LIMIT - adsetLength;

      if (allAds >= FB.ADSET_LIMIT) {
        //Add new ads to the current adset until limit
        const currentAds = ads.slice(0, adsAmount);
        adset.ads = adset.ads.concat(currentAds);
        this.modifyAdset(adset, adIdx, campIdx, geoIdx);
        // Rest of the ads split into equal parts
        const nextAds = ads.slice(adsAmount, ads.length);
        const adsList = this.makeBatch(nextAds, FB.ADSET_LIMIT);
        adsList.forEach(list => {
          adsets.push({ type: campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate, ads: list });
        });

        campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], adsets };
        //Add to the new adsets
        this.setState({ campaignList });
      } else {
        adset.ads = adset.ads.concat(ads);
        this.modifyAdset(adIdx, adset, campIdx, geoIdx);
      }
    }
  }
  updateAdsetWithVideos(adIdx, videos, campIdx, geoIdx) {
    const campaignList = this.state.campaignList;
    if (videos) {
      const adsets = campaignList[campIdx].geoList[geoIdx].adsets;
      const adset = campaignList[campIdx].geoList[geoIdx].adsets[adIdx];
      const ads = videos.map(video => {
        return { video_url: getFullPath(video), text: '', title: '' };
      });
      let adsetLength = adset.ads.length;
      const allAds = adsetLength + ads.length;
      let adsAmount = FB.ADSET_LIMIT - adsetLength;

      if (allAds >= FB.ADSET_LIMIT) {
        //Add new ads to the current adset until limit
        const currentAds = ads.slice(0, adsAmount);
        adset.ads = adset.ads.concat(currentAds);
        this.modifyAdset(adset, adIdx, campIdx, geoIdx);
        // Rest of the ads split into equal parts
        const nextAds = ads.slice(adsAmount, ads.length);
        const adsList = this.makeBatch(nextAds, FB.ADSET_LIMIT);
        adsList.forEach(list => {
          adsets.push({ type: campaignList[campIdx].geoList[geoIdx].adsetTypeToCreate, ads: list });
        });

        campaignList[campIdx].geoList[geoIdx] = { ...campaignList[campIdx].geoList[geoIdx], adsets };
        //Add to the new adsets
        this.setState({ campaignList });
      } else {
        adset.ads = adset.ads.concat(ads);
        this.modifyAdset(adIdx, adset, campIdx, geoIdx);
      }
    }
  }

  async parseDuplicateData() {
    const sourceData = this.props.sourceData;
    let {
      objective,
      bid_strategy,
      billing_event,
      conversion,
      campaign: { adsets },
      name,
      widget_id,
      // campaignNote,
      campaignCreator,
      budgetType
    } = sourceData;
    const sourceAds = getCampaignAds(sourceData);

    adsets = adsets.map(adset => {
      adset.ads = adset.ads.filter(ad => {
        return !(typeof ad.blacklisted === 'boolean' && ad.blacklisted === true);
      });
      return adset;
    });
    let blacklistedAds = [];
    let tags = '';
    if (this.isSwag) {
      tags = sourceData && sourceData.tags ? [sourceData.tags] : '';
    }

    let blockedAds = sourceAds.filter(ad => ad.blacklisted && typeof ad.blacklisted === 'boolean');

    sourceAds.forEach(ad => {
      if (ad.blacklisted && typeof ad.blacklisted !== 'boolean') {
        blacklistedAds.push({
          campaign_name: ad.name,
          creative_id: ad.creative,
          blacklisted_bm: ad.blacklisted
        });
      }
    });

    if (blockedAds && blockedAds.length > 0) {
      toast.error(
        `You are trying to duplicate campaign ${name} with blacklisted ads. ${blockedAds.length} ads will not be added for duplication`
      );
    }
    this.setState({ blacklistedAds, blockedAds, sourceAds, tags });

    const parsedName = parseCampaignName(PLATFORMS.FACEBOOK, name);
    this.fetchCampaigns(this.props.account);
    const bidStrategyType = bid_strategy;
    let bidAmount = 0;
    let bmId = null;
    if (budgetType.includes('campaign')) {
      bidAmount = adsets[0].bid_amount;
    }
    const preparedAdsets = [];
    let siteList = this.props.siteList;
    let customConversions = await this.fetchOneConversionList(this.props.account);
    if (this.props.account) {
      const acc = getAccount(this.props.account, this.props.accounts);
      siteList = this.getUpdatedSiteList(this.props.account, acc);
    }

    for (const adset of adsets) {
      const parsedName = parseCampaignName(PLATFORMS.FACEBOOK, adset.name);
      const roasAverageFloor =
        adset.bid_constraints && adset.bid_constraints.roas_average_floor
          ? adset.bid_constraints.roas_average_floor
          : undefined;
      let domain;
      let cc = [];
      let mobilePlatforms = parsedName.platform === 'm' ? FB.MOBILE_PLATFORM_LIST : [];
      adset.geoType = 'include';
      adset.geoName = parsedName.country;
      adset.duplicationType = 'group';
      adset.roasAverageFloor = roasAverageFloor ? roasAverageFloor / 10000 : 0.001;
      let optionalFilter = { filterConverions: false, cc: FB.QUIZ_CONVERSIONS };

      if (this.props.account) {
        const acc = getAccount(this.props.account, this.props.accounts);
        siteList = this.getUpdatedSiteList(this.props.account, acc);
        domain = this.getDefaultSite(acc, siteList);
        if (widget_id.includes(FB.QUIZ)) {
          optionalFilter.filterConverions = true;
        }
        if (acc && acc.business_mgr_id && customConversions) {
          customConversions = await filterConversionsByBmId(
            customConversions,
            acc.business_mgr_id,
            this.props.userMail,
            optionalFilter,
            FB.DYNAMIC_CC_USERS,
            this.isSwag
          );
        }
        bmId = acc.business_mgr_id;
      }
      let conversionToUse = customConversions.find(el => el.id === adset.conversion);
      if (conversionToUse) {
        cc.push({ label: conversionToUse.name, value: conversionToUse.id });
      }
      const adUrlTags = adset?.ads[0]?.url_tags;

      if (adUrlTags?.includes('pixel_value=')) {
        const result = getInternalConversion(adUrlTags);
        if (result) {
          cc.push(result);
        }
      }
      if (!cc && adUrlTags) {
        let result = getPurchaseConversion(adUrlTags);
        if (result) {
          cc.push(result);
        }
      }
      adset.site_code = domain || parsedName.domain;
      adset.cc = cc;
      adset.countries = [parsedName.country];
      adset.mobilePlatforms = mobilePlatforms;
      adset.platform = parsedName.platform;
      adset.language = adset.language.join();
      adset.adsetTypeToCreate = adset.type;
      adset.adsets = [{ ...adset }];
      adset.toggleGeo = true;
      adset.toggleAd = true;
      adset.website = getWebsite(this.state, parsedName.domain);
      adset.billing_event = billing_event;
      adset.campaignNote = [];
      adset.ageList = {
        minAge: FB.AGE_LIST,
        maxAge: FB.AGE_LIST
      };
      adset.bidStrategyType = adset.bid_strategy;
      adset.bidAmount = adset.bid_amount ? adset.bid_amount / 100 : 0;
      adset.website = getWebsite(this.state, parsedName.domain);
      adset.status = 'active';
      adset.autoPlacements = false;
      adset.device = parsedName.platform === 'm' ? 'Mobile' : parsedName.platform === 'd' ? 'Desktop' : '';
      adset.adNaming = 'Default';
      preparedAdsets.push(adset);
    }
    const creator = this.state.campaignCreator ? getNewCreatorValue(campaignCreator, this.props.creatorsList) : '';
    if (!creator && this.state.creatorsList && this.state.creatorsList.length > 0) {
      console.warn(`Campaign ${name} doesn't have creator`);
      toast.error(`Campaign ${name} doesn't have creator`);
    }
    const clv = articleCodeLanguageVersion(this.props.articleList, widget_id, parsedName.article);
    return {
      [GLOBAL.KEY_WIDGET_CODE_NAME]: clv[0],
      [GLOBAL.KEY_WIDGET_LANGUAGE_CODE]: clv[1],
      [GLOBAL.KEY_WIDGET_VERSION]: clv[2],
      campaignCreator: creator,
      conversion: conversion,
      sourceCampaignName: name,
      prefix: parsedName.prefix,

      campaignList: [
        {
          destinationAccount: this.props.account,
          accountName: parsedName.fullAccountName,
          bmId: bmId,
          campaignObjective:
            objective.toLowerCase() === 'conversions' || objective.toLowerCase() === 'outcome_sales'
              ? 'outcome_sales'
              : 'outcome_traffic',
          campaignStartTime: moment()
            .add(1, 'day')
            .startOf('day'),
          toggleCampaign: true,
          budgetType: getBudgetInfo(sourceData).budgetType,
          budget: this.isSwag ? getBudgetInfo(sourceData).budget / 100 : 15,
          tempBudget: this.isSwag ? getBudgetInfo(sourceData).budget / 100 : 15,
          selectArticleCreativeData: {},
          customConversions: customConversions,
          siteList: siteList,
          bidStrategyType: bidStrategyType,
          bidAmount: bidAmount ? bidAmount / 100 : 0,
          geoList: preparedAdsets
        }
      ]
    };
  }
}

export default FacebookCreate;
